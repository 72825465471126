import {Drawer, Input,Form,  Tag, Select,Table, Layout, Breadcrumb, Divider, Space, Button, Col, Row, notification, Switch } from 'antd';
import * as actions from '@actions/user'

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../utils/common';
import './index.css'
import { ConsoleSqlOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

@connect(state => state.user, { ...actions })
class Profile extends Component {
   formRef = React.createRef();

  constructor() {
    super(...arguments)
    
    this.state = {
      userList: [],
      showUserModal: false,
      drawerOpened:false,
      userTotal:0
      
    }
    this.selectedBatch = []

    this.editedUser ={
      isValid:false,
      userId:0,username:'',password:'',confirmPassword:'',mobile:'',email:'',
      realName:'',orgName:'',orgCode:'',province:'',city:'',county:'',address:'',postcode:'',cop:''
    }
    this.modelName = '个人信息'
    this.rules = Common.initRules();
  }




  componentDidMount() {
    let userInfo = Common.getUserInfo()
    this.loadUserDetail(userInfo.user.userId)
    }

  openNotification = (desc) => {
    notification.info({
      message: `提示`,
      description: desc,
      placement: 'top',
    });
  }
 
  loadUserDetail =(userId)=>{
    this.props.dispatchUserDetail({userId:userId}).then(res=>{
      if(res.code!=0){
        this.openNotification(res.message);
      }
      else{
        this.editedUser = res.result;
        this.editedUser['originPassword'] = res.result.password
        this.editedUser.password = this.editedUser.password.substring(0,18)
        this.editedUser['confirmPassword'] = this.editedUser.password
        this.editedUser.status = this.editedUser.status===1
        
        setTimeout(()=>{
          this.formRef.current.setFieldsValue(this.editedUser );
        },100)

      }
    }).catch(err=>{
      this.openNotification(err.toString)
    })
  }


  onSave=()=>{
    this.formRef.current.validateFields().then((e)=>{
      let user = this.formRef.current.getFieldsValue(true)
      if(user.password===this.editedUser.originPassword.substring(0,18)){
        this.openNotification('密码未发生变化')
        return
      }
      if(user.password!==user.confirmPassword){
        this.openNotification('密码需要保持一致')
        return
      }

      this.props.dispatchUserUpdate({userId:user.userId,password:user.password}).then(res=>{
        if(res.code==0){
          this.openNotification('修改成功')
        } else{
          this.openNotification('修改失败.'+res.message)
        }
      }).catch(err=>{
        this.openNotification(err.toString())
      })
    }).catch(err=>{
      this.openNotification('请检查所填内容')
      console.log('Verify---->failed',err)
    })
  }



  render() {
    return ([

      <Layout key="content">
        <Navbar />
        <Layout >
          <Sidebar />
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>管理平台</Breadcrumb.Item>
              <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={8}>
              <Col span={6} >

              </Col>
              <Col span={2}> </Col>
              <Col span={8}></Col>

              <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" style={{ marginRight: '10px' }} onClick={this.onSave.bind(this)}>保存</Button>
              </Col>
            </Row>
            <Content
              className="site-layout-background"
              style={{
                padding: '8px',
                margin: 0,
                minHeight: 480,

              }}
            >
 <Form layout="vertical"  
       ref={this.formRef}
       name="control-ref"
       onFinishFailed={console.log('FAIL SUBMIT------->',this.formRef.current)}
      ><Row gutter={16}>
        <Col span={24}>
        <Divider orientation="left">安全信息</Divider>
        </Col>
      </Row>
      <Row gutter={16}>
      <Col span={6}>
      <Form.Item name='password' label='密码' rules={this.rules.password}>
              <Input.Password placeholder="请输入密码" />
      </Form.Item>
      </Col>
      </Row>
      <Row gutter={16}>
              <Col span={6}>
      <Form.Item name='confirmPassword' label='确认密码' rules={this.rules.confirmPassword}>
            <Input.Password placeholder="请再次输入密码" />
      </Form.Item>
      </Col>
        </Row>
      <Row gutter={16}>
        <Col span={24}>
        <Divider orientation="left">个人资料</Divider>
        </Col>
      </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="realName"
              label="真实姓名"
            >
              <Input disabled placeholder="真实姓名" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label="手机号"
            >
              <Input
              disabled
                style={{
                  width: '100%',
                }}
                placeholder="请输入正确的手机号"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="orgName"
              label="机构名称"
            >
              <Input 
              disabled
                placeholder="请填写组织机构名称"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="orgCode"
              label="统一信用代码"
            >
              <Input
              disabled
                placeholder="请填写统一信用代码"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              name="province"
              label="省"
            >
              <Input
              disabled
                placeholder="请填写所在身份"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="city"
              label="市/地区"
            >
              <Input
              disabled
                placeholder="请填写所在市或地区"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="county"
              label="区/县"
              rules={this.rules.general}
            >
              <Input
              disabled
                placeholder="请填写所在区或县"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={24}>
            <Form.Item
              name="address"
              label="机构地址"
            >
              <Input
              disabled
                placeholder="请填写地址"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="邮箱"
            >
              <Input 
              disabled
                placeholder="请输入邮箱地址"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
           
            <Form.Item
              name="postcode"
              label="邮编"
            >
              <Input
              disabled
                placeholder="请填写地址"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
            </Content>
          </Layout>
        </Layout>
        <Bottom />
      </Layout>,
   

    ]
    )
  }
}
export default withRouter(Profile)