export const STOCK_QUERY_TRADE = 'STOCK_QUERY_TRADE'

export const STOCK_QUERY_COLLECT = 'STOCK_QUERY_COLLECT'

export const STOCK_QUERY_ORDER = 'STOCK_QUERY_ORDER'

export const STOCK_ADD_COLLECT = 'STOCK_ADD_COLLECT'

export const STOCK_ADD_CONVERT = 'STOCK_ADD_CONVERT'

export const STOCK_ADD_REVOKE = 'STOCK_ADD_REVOKE'

export const STOCK_AUDIT_TRADE = 'STOCK_AUDIT_TRADE'

export const STOCK_DETAIL = 'STOCK_DETAIL'

export const STOCK_ADD_TRADE = 'STOCK_ADD_TRADE'

export const STOCK_QUERY_SIMPLE_BATCH = 'STOCK_QUERY_SIMPLE_BATCH'

export const STOCK_BATCH_QRCODE = 'STOCK_BATCH_QRCODE'

export const STOCK_EXPORT = 'STOCK_EXPORT'
