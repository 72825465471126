import { Layout} from 'antd';
import React,{ Component } from 'react'
import { withRouter } from 'react-router';
import '@components/bottom.css'
const {Footer} = Layout

class Bottom extends React.Component {

    render(){
        return (
            <Footer style={{ textAlign: 'center' }}><span style={{ marginRight: 12 }}><a href="https://beian.miit.gov.cn/">京ICP备15030672号-18</a></span>大兴安岭地区绿色产业发展联盟商会  ©2022 </Footer>
        )
    }
}

export default withRouter(Bottom)