export default class Common {

    static host = 'https://www.tracedxal.com'
    static pageSize = 5

    static getQueryString(name) {
        name = name.replaceAll('#','')
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = window.location.href.substr( window.location.href.lastIndexOf('?')+1).match(reg)
        //var r = window.location.search.substr(1).match(reg);
        if (r != null) {
        return unescape(r[2]);
        }
        return null;
        }
    static permitCodeToName(code){
        let baseCode = [
            {code:65535,name:'管理员'},
            {code:128,name:'认证机构'},
            {code:64,name:'收集点'},
            {code:16,name:'加工厂'},
            {code:8,name:'零售商'}
        ]
        if(code==65535)
            return [{code:65535,name:'管理员'}]
        let arrRet = [];
        for(let i=0;i<baseCode.length;i++){
            if((baseCode[i].code&code)==baseCode[i].code)
                arrRet.push(baseCode[i])
        }
        return arrRet;
    }

    static getUserInfo(){
        let uinfo = window.localStorage.getItem('token')
        if(uinfo==null||uinfo==undefined)
            return null;
        return JSON.parse(uinfo)
    }

    static  addKey(arr,...args){
        for(let i=0;i<arr.length;i++)
        {
            arr[i]['key'] = i
            for(let j=0;j<args.length;j++){
                arr[i][args[j]] = 0
            }
        }
        return arr
      }

      /***
       * rule模板
       *   {
              validator(rule, value, callback) {
                if(value.length>1&&value.length<20&&delegate)
                    return Promise.resolve()
                  else{
                    return Promise.reject()
                  }
              },
            }
       *
       */
      static initRules = ()=>{
        return {
          realName:[
            {
              required: true,
              message: '姓名不能为空'
            },
            {
              min:2, message:'不能少于2个字'
            },
            {
              max:20, message: '名称不超过20个字符'
            }
          ],
        mobile:[
            {
              required: true,
              message: "请输入手机号"
            },
            {
              pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g"),
              message: '请输入正确的手机号'
            }
        ],
        password:[
          {
            required: true,
            message: '密码必填'
          },
          {
            min:6, message:'不能少于6位'
          },
          {
            max:18, message: '超过18位不好记'
          }
        ],
        confirmPassword:[
          {
            required: true,
            message: '密码必填'
          },
          ({getFieldValue})=>({
            validator(rule,value){
              if(!value || getFieldValue('password') === value){
                return Promise.resolve()
              }
              return Promise.reject("两次密码输入不一致")
            }
          })
      ],
      email:[
        {
          required: true,
            message: '邮箱必填',
            type:'email'
        }
      ],
      orgCode:[
        {
          required: true,
          message: '统一社会信用代码必填'
        },
        {
          min:9, message:'长度最少是9位'
        },
        {
          max:18, message:'长度最多是18位'
        },
      ],
      postcode:[
        {
          required: true,
          message: '邮编必填'
        },
        {
          len:6, message:'长度是6位'
        },
      ],
      general:[
        {
          required: true,
          message: '必填项'
        },
        {
          min:2, message:'长度最少2位'
        },
        {
          max:20,message:'最多20位'
        }
      ]

}
      }}
