import { Form,Select, InputNumber, Layout,Breadcrumb,Divider,Space,Modal,Button,Col, Row,Input,notification } from 'antd';
import *  as actions from '@actions/stock'
import * as userAction from '@actions/user'
import * as goodsAction from '@actions/goods'
import React,{ Component  } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import zhCN from 'antd/es/locale/zh_CN'; 
// 引入国际化配置
import { ConfigProvider,Table } from 'antd';
import Common from '../../../utils/common';
import './index.css'



const {  Content} = Layout;
const { Search,TextArea } = Input;
const {Option} = Select;


@connect(state=>state,{...actions,...userAction,...goodsAction})
class Collect extends Component{

    constructor(){
        super(...arguments)
        this.state = {
            stockList:[],
            showCollectModal:false,
            goodsList:[],
            selectedStockIndex:-1,
            stockInfo:{
                goodsId:0,
                contractNo:'',
                volume:1,
                remark:''
            },
            stockTotal:0
        }
        this.selectedBatch = []
        this.columns =[  {
            title: '库存id',
            dataIndex: 'stockId',
            key: 'stock_id',
          },
          {
            title: '商品',
            dataIndex: 'cname',
            key: 'goods_cname',
          },
          {
            title: '合同号',
            dataIndex: 'contractNo',
            key: 'contract_no',
          },
          {
            title: '数量',
            dataIndex: 'tradingVolume',
            key: 'trading_volume',
          },
          {
            title: '时间',
            dataIndex: 'createTime',
            key: 'create_time',
          }
        ]
        this.modelName = '收集管理'
    }


    componentDidMount(){
        this.loadData(1)
        this.loadGoodsData()
    }

    openNotification = (desc) => {
        notification.info({
          message: `提示`,
          description: desc,
          placement:'top',
        });
      }

    /**
     * 加载交易单列表
     * @param {页码} page 
     * @param {类型:all/in/out} type 
     */
    loadData = (page)=>{
        this.props.dispatchStockQueryCollect({pageIndex:page}).then(
            (res)=>{
                if(res.code==0){
                    let data = res.result.records;
                    for(let i=0;i<data.length;i++){
                        data[i]['cname'] = data[i].goods.cname
                        data[i]['key'] = i
                    }
                    this.setState({stockList:data,stockTotal:res.result.total})
                }else{
                    this.openNotification(res.message)
                }
            }
        ).catch(err=>{this.openNotification(err.toString())})
    }

        /**
     * 加载商品列表
     * @param {页码} page 
     */
    loadGoodsData = ()=>{
    this.props.dispatchGoodsQueryAll({pageIndex:0,status:1}).then(
        (res)=>{
            if(res.code==0){
                let data = res.result.records;
                data = Common.addKey(data)
                console.log('GOODSLIST====>',data)
                this.setState({goodsList:data})
            }else{
                this.openNotification(res.message)
            }
        }
    ).catch(err=>{this.openNotification(err.toString())})
    }

    addCollect = ()=>{
        if(this.state.stockInfo.goodsId==0||this.state.stockInfo.contractNo.length==0){
            this.openNotification('请检查填写信息')
            return
        }
        console.log(this.state.stockInfo)
        this.props.dispatchStockAddCollect(this.state.stockInfo).then(res=>{
            if(res.code==0){
                this.openNotification('添加成功')
                this.loadData(1)
                this.closeCollectModal()
            }else{
                this.openNotification('添加失败，'+res.message)
                return
            }
        }).catch(err=>{
            this.openNotification('添加失败，'+err.toString())
        })
    }




    /**
     * 显示卖出单填写窗口
     */
    handleShowCollectModal = ()=>{
      this.setState({showCollectModal:true})
    }

    closeCollectModal = () =>{
        this.setState({showCollectModal:false})
      }
    /**
     * 单行文本框回调
     * @param {事件载体} e 
     */
    handleInput = (e)=>{
      let stock = this.state.stockInfo;
      stock[e.target.name] = e.target.value
      this.setState({stockInfo:stock})
    }

    handleInputNumber = (e)=>{
        let stock = this.state.stockInfo;
        stock.volume = e
        this.setState({stockInfo:stock})
    }

    handleSelect = (e,item)=>{
        let stock = this.state.stockInfo;
        stock.goodsId = parseInt(item.value)
        console.log("GID",item.value,stock.goodsId)
        this.setState({stockInfo:stock})
    }

    handleDownload = ()=>{
      this.props.dispatchStockExport({filename:'export.xlsx',  type:'collect'}).then(res=>{
          if(res.code===0){
            this.openNotification('下载成功')
          } else{
            this.openNotification(res.message)
          }
      }).catch(err=>{
      this.openNotification(err.toString())  
      })
  }

    onSearch = ()=>{
      console.log("search")
      console.log("PARAM:",Common.getQueryString("roleId"))
    }

    addKey = (arr)=>{
      for(let i=0;i<arr.length;i++)
      arr[i]['key'] = i
      return arr
    }

    render(){
        return ([

          <Layout key="content">
          <Navbar />
        <Layout >
        <Sidebar/>
  <Layout
    style={{
      padding: '0 24px 24px',
    }}
  >
    <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>管理平台</Breadcrumb.Item>
          <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{margin:'5px 0'}}/>
        <Row gutter={8}>
        <Col span={6} ><Button type="primary" style={{marginLeft:'8px'}} onClick={this.handleDownload.bind(this)}>导出数据</Button>
       <Button type="primary" style={{marginLeft:'8px'}} onClick={this.handleShowCollectModal.bind(this)}>采集入库</Button>
        </Col>
        <Col span={2}> </Col>
        <Col span={8}></Col>
        
        <Col span={8} style={{display:'flex',justifyContent:'flex-end'}}>
          {/*<Search placeholder="输入商品名称" onSearch={this.onSearch.bind(this)} style={{width: 200,marginRight:'8px'}}    />*/}
    </Col>
        </Row>
        <Content
          className="site-layout-background"
          style={{
            padding: '8px',
            margin: 0,
            minHeight: 480,

          }}
        >
          <ConfigProvider locale={ zhCN }> 
            <Table
        columns={this.columns}
        dataSource={this.state.stockList}  
        pagination={{
          showQuickJumper:true,
          pageSize:Common.pageSize,
          total:this.state.stockTotal,
          onChange:(pageNo, _) => this.loadData(pageNo)
        }}
      />
      </ConfigProvider>
      <Modal
        title="填写收集单"
        style={{
          top: 20,
        }}
        centered
        visible={this.state.showCollectModal}
        okText='提交'
        cancelText='取消'
        onOk={this.addCollect.bind(this)}
        onCancel={this.closeCollectModal.bind(this)}
        width={600}
      >
        <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout="horizontal"
       
      >
        <Form.Item label="商品" name="taget">
        <Select
          
          placeholder="选择商品"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          onChange={this.handleSelect.bind(this)}
        >
          {this.state.goodsList.map((item)=>{return <Option key={item.key} value={item.goodsId}>{item.cname}</Option>})}
         
        </Select>
        </Form.Item>
        <Form.Item label="合同编号">
          <Input placeholder='填写合同编号' name='contractNo' onChange={this.handleInput.bind(this)}/>
        </Form.Item>
        <Form.Item label="数量">
        <InputNumber min={1}  defaultValue={1} value={this.state.stockInfo.volume} onChange={this.handleInputNumber.bind(this)} />
        </Form.Item>
        <Form.Item label="备注">
        <TextArea name='remark' rows={3} placeholder="最多100个汉字" maxLength={256} value={this.state.stockInfo.remark} onChange={this.handleInput.bind(this)}/>
        </Form.Item>
        </Form>
      </Modal>
      </Content>
      </Layout>
    </Layout>
    <Bottom/>
          </Layout>,
 
          ]
        )
    }
}
export default withRouter(Collect)