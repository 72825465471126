import { Form, Tag, InputNumber, Select, Table, Layout, Breadcrumb, Divider, Space, Modal, Button, Col, Row, Input, notification } from 'antd';
import *  as actions from '@actions/goods'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../utils/common';
import './index.css'


const { Content } = Layout;
//const { Search } = Input;
const { Option } = Select;


@connect(state => state, { ...actions })
class Goods extends Component {
  formRef = React.createRef();
  constructor() {
    super(...arguments)

    this.userinfo = Common.getUserInfo()
    console.log(this.userinfo)
    this.state = {


      showGoodsModal: false,
      goodsList: [],
      allGoodsList: [],
      goodsInfo: {
        cname: '',
        ename: '',
        type: 1,
        categoryId: 1,
        ingredients: "[]",
        firstRawVolume: 1000,
        firstRawGoodsId: 0
      },
      mode:'insert',
      rawEnable: true,
      total: 0
    }
    this.currentPageIndex = 1

    this.columns = [{
      title: '商品id',
      dataIndex: 'goodsId',
      key: 'goodsId',
    },
    {
      title: '英文名',
      dataIndex: 'ename',
      key: 'ename',
    },
    {
      title: '中文名',
      dataIndex: 'cname',
      key: 'cname',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        switch (type) {
          case 0:
            return <Tag color="green">原料</Tag>
          case 1:
            return <Tag color="magenta">半成品</Tag>
          default:
            return <Tag color="geekblue">成品</Tag>
        }
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return status === 0 ? <Tag color="blue">待审核</Tag> : status === 1 ? <Tag color="lime">正常</Tag> :  status===2? <Tag color="lime">驳回</Tag>:<Tag color="blue">锁定</Tag>
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        console.log('STATUS=====>', this.userinfo.user.permitCode, record.status)
        if (this.userinfo.user.permitCode === 65535){
          if(record.status === 0) {
      
          return (
            <Space size="middle">
              <a onClick={this.auditGoods.bind(this, record.goodsId, 1)}>审核</a>
              <a onClick={this.auditGoods.bind(this, record.goodsId, 0)}>驳回</a>
            </Space>
          )
        }
        if(record.status === 1){
          console.log('STATUS--->')
          return (
            <Space size="middle">
              <Button type='link' onClick={this.lockGoods.bind(this, record.goodsId, 1)}>锁定</Button></Space>
          )}
          if(record.status === 3){
            return (
              <Space size="middle">
                <Button type='link' onClick={this.lockGoods.bind(this, record.goodsId, 0)}>解锁</Button></Space>
            )}
        
      }
        if(this.userinfo.user.userId === record.creatorUid&&(record.status==0||record.status==2)){
          return (
            <Space size="middle"><Button type='link' onClick={this.handleShowGoodsModal.bind(this,record)}>编辑</Button></Space>
          )
        }
        

      },
    },
    ]
    this.modelName = '商品管理'
  }


  componentDidMount() {
    this.loadData(1)
    this.loadGoodsData()
  }

  openNotification = (desc) => {
    notification.info({
      message: `提示`,
      description: desc,
      placement: 'top',
    });
  }

  auditGoods = (goodsId, isPass) => {
    this.props.dispatchGoodsAudit({ goodsId: goodsId, isPassed: isPass }).then(res => {
      if (res.code === 0) {
        this.updateGoodsStatusOnUI(goodsId,isPass==1?1:2)
        this.openNotification(isPass === 1 ? "审核通过" : "驳回申请")
        return;
      }
      else
        this.openNotification(res.message)
    }).catch(err => {
      this.openNotification(err.toString())
    })
  }

  lockGoods = (goodsId,isLock) =>{
    this.props.dispatchGoodsLock({ goodsId: goodsId, isLock: isLock }).then(res => {
      if (res.code === 0) {
        this.updateGoodsStatusOnUI(goodsId,isLock===1?3:1)
        this.openNotification(isLock === 1 ? "锁定成功" : "解锁成功")
        return;
      }
      else
        this.openNotification(res.message)
    }).catch(err => {
      this.openNotification(err.toString())
    })
  }

  updateGoodsStatusOnUI(goodsId,status){
   
    let data = this.state.goodsList
    let item = data[data.findIndex(x => x.goodsId === goodsId)]
    item.status = status
    item.key = item.key * 10000 + Math.ceil(Math.random()*1000)

    this.setState({ goodsList: data },()=>{ console.log('UPDATE UI',data)})
  }

  /**
   * 加载商品列表
   * @param {页码} page 
   * @param {类型:all/in/out} type 
   */
  loadData = (page) => {
    console.log('PAGE GO TO----->', page)
    this.currentPageIndex = page
    this.props.dispatchGoodsQueryAll({ pageIndex: page }).then(
      (res) => {
        if (res.code === 0) {
          let data = Common.addKey(res.result.records);
          this.setState({ goodsList: data, total: res.result.total }, () => { console.log('page0---->', this.state) })
        } else {
          this.openNotification(res.message)
        }
      }
    ).catch(err => { this.openNotification(err.toString()) })
  }

  /**
* 加载商品列表
* @param {页码} page 
*/
  loadGoodsData = () => {
    this.props.dispatchGoodsQueryAll({ pageIndex: 0, status: 1 }).then(
      (res) => {
        if (res.code == 0) {
          let data = res.result.records;
          data = Common.addKey(data)
          this.setState({ allGoodsList: data })
        } else {
          this.openNotification(res.message)
        }
      }
    ).catch(err => { this.openNotification(err.toString()) })
  }

  saveGoods = () => {
    this.formRef.current.validateFields().then((e) => {
      let data = this.formRef.current.getFieldsValue(true);
      //console.log('SUBMIT------->', this.formRef.current.getFieldsValue(true))
      //配方处理
      if (data.type == 0)
      data['ingredients'] = '[]'
    else if (data.firstRawGoodsId == 0 || data.firstRawVolume == 0) {
      this.openNotification('半成品、成品必须设定配方')
      return
    } else {
      data['ingredients'] = JSON.stringify([{ goodsId: data.firstRawGoodsId, volume: data.firstRawVolume }])
    }
    //console.log('SUBMIT------->', data)
    this.props.dispatchGoodsSave(data).then(res => {
      if (res.code == 0) {
        this.openNotification(data.goodsId==0?'添加成功':'修改成功')
        this.loadData(this.currentPageIndex)
        this.closeGoodsModal()
      } else {
        this.openNotification('操作失败，' + res.message)
        return
      }
    }).catch(err => {
      this.openNotification('操作失败，' + err.toString())
    })
    })
    

  }

  /**
   * 显示卖出单填写窗口
   */
  handleShowGoodsModal = (obj) => {
    let goods = { goodsId: 0, type: 0, categoryId:1,cname: '', ename: '', firstRawGoodsId: 0, firstRawVolume: 1000 }
    let mode = 'insert'
    let rawEnable = false
    if('goodsId' in obj){
      goods = obj
      rawEnable = goods.type>0;
      let ingredients = JSON.parse(obj.ingredients)
      
      if(ingredients.length<1||(!('goodsId' in ingredients[0] && 'volume' in ingredients[0])))
      {
         if(goods.type>0)
              this.openNotification("配方数据错误，自动设为默认值")
          ingredients = [{goodsId:0,volume:1000}]
      }
      goods['firstRawGoodsId'] =  ingredients[0].goodsId
      goods['firstRawVolume'] = ingredients[0].volume
      mode = 'update'
    }
    this.setState({ showGoodsModal: true,mode:mode,rawEnable: rawEnable}, () => {
      setTimeout(() => {
        console.log('Init form----->',this.formRef.current)
        this.formRef.current.setFieldsValue(goods);
      }, 200)
    })
  }

  closeGoodsModal = () => {
    this.setState({ showGoodsModal: false })
  }
  /**
   * 单行文本框回调
   * @param {事件载体} e 
   */
  handleInput = (e) => {
    let goods = this.state.goodsInfo;
    goods[e.target.name] = e.target.value
    this.setState({ goodsInfo: goods })
  }

  handleInputNumber = (e) => {
    let goods = this.state.goodsInfo;
    goods.firstRawVolume = e
    this.setState({ goodsInfo: goods })
  }

  handleSelect = (e, item) => {
    console.log('SELECT->>>>>', e, item)
    let goods = this.state.goodsInfo;
    goods.type = parseInt(item.value)
    let enable = goods.type > 0;
    this.setState({ goodsInfo: goods, rawEnable: enable },()=>{
      console.log('disable ok')
    })
  }

  handleRawSelect = (e, item) => {
    let goods = this.state.goodsInfo;
    goods.firstRawGoodsId = parseInt(item.value)
    this.setState({ goodsInfo: goods })
  }

  onSearch = () => {
    console.log("search")
    console.log("PARAM:", Common.getQueryString("roleId"))
  }



  render() {
    let that = this
    return ([

      <Layout key="content">
        <Navbar />
        <Layout >
          <Sidebar />
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>管理平台</Breadcrumb.Item>
              <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
            </Breadcrumb>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={8}>
              <Col span={6} ><a href='https://trace.lsjzcp.com/api/goods/export'><Button type="primary" style={{ marginLeft: '8px' }}>导出数据</Button></a>
                <Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleShowGoodsModal.bind(this)}>登记商品</Button>
              </Col>
              <Col span={2}> </Col>
              <Col span={8}></Col>

              <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Search placeholder="输入商品名称" onSearch={this.onSearch.bind(this)} style={{ width: 200, marginRight: '8px' }} />  */}
              </Col>
            </Row>
            <Content
              className="site-layout-background"
              style={{
                padding: '8px',
                margin: 0,
                minHeight: 480,

              }}
            >
              <Table
                columns={this.columns}
                dataSource={this.state.goodsList}
                pagination={{
                  showQuickJumper: true,
                  pageSize: Common.pageSize,
                  total: this.state.total,
                  onChange: (pageNo, _) => this.loadData(pageNo)

                }}
              />
              <Modal
                title={this.state.mode=='insert'?'添加商品':'修改商品'}
                style={{
                  top: 20,
                }}
                centered
                visible={this.state.showGoodsModal}
                okText='提交'
                cancelText='取消'
                onOk={this.saveGoods.bind(this)}
                onCancel={this.closeGoodsModal.bind(this)}
                width={600}
              >
                <Form
                  ref={this.formRef}
                  name="control-ref"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  layout="horizontal"
                  onFinishFailed={console.log('FAIL SUBMIT----1--->', this.formRef.current)}
                >
                  <Form.Item hidden={true} name='goodsId'>
                    <Input/>
                  </Form.Item>
                  <Form.Item hidden={true} name='categoryId'>
                    <Input/>
                  </Form.Item>
                  <Form.Item label="商品类型" name="type"  shouldUpdate={true}>
                    <Select  onChange={this.handleSelect.bind(this)} >
                      <Option key={0} value={0}>原料</Option>
                      <Option key={1} value={1}>半成品</Option>
                      <Option key={2} value={2}>成品</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="商品英文名" name='ename' 
                  rules={[
                      {
                        required: true,
                        message: '英文名必填'
                      },
                      {
                        max:16, message: '最大字数为16'
                      }
                  ]}
                  >
                    <Input placeholder='填写英文名' />
                  </Form.Item>
                  <Form.Item label="商品中文名" name='cname'
                  rules={[
                    {
                      required: true,
                      message: '中文名必填'
                    },
                    {
                      max:16, message: '最大字数为16'
                    }
                  ]}
                  >
                    <Input placeholder='填写中文名' />
                  </Form.Item>
                  <Form.Item label="成分" name='firstRawGoodsId'
               
                  >
                    <Select disabled={!this.state.rawEnable} 
                    >
                      {this.state.allGoodsList.map(item => {
                        return (<Option key={item.key} value={item.goodsId}>{item.cname}</Option>)
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label='每1000单位原料可生产量' name='firstRawVolume' 
                     rules={[
                      {
                        min:1,transform: (value) => String(value), message:'长度最少是1位'
                      },
                      {
                        max:4,transform: (value) => String(value), message:'长度最多是4位'
                      }]
                    }
                    shouldUpdate={true}
                  >
                  <InputNumber disabled={!this.state.rawEnable} min={1} />
                  </Form.Item>

                </Form>
              </Modal>
            </Content>
          </Layout>
        </Layout>
        <Bottom />
      </Layout>,

    ]
    )
  }
}
export default withRouter(Goods)