import {  Layout,Input ,Button, Checkbox, Form,notification} from 'antd';
import { connect } from 'react-redux'
import React,{ Component } from 'react'
import { withRouter } from 'react-router';
import * as actions from '@actions/user'
import { USER_TOKEN } from '@constants/user'
import 'antd/dist/antd.css'
import './index.css'
import Header from '@components/header';

const {  Content } = Layout;

@connect(state=>state.user, {...actions})
class Login extends Component{
    constructor(){
        super(...arguments)
        this.state = {
            username:'haojing',
            password:'1234',
        }
    }

    userinfo = {}

    onFinish = (values) => {
      console.log('Success:', values);
    }

    onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }

    login = ()=>{
      
      let form = new FormData()
      form.append('username',this.state.username)
      form.append('password',this.state.password)
      console.log(form)
      this.props.dispatchUserLogin(form).then(
        res=>{
          if(res.code==0){
            this.props.history.push('/dashboard')
          }else{
            this.openNotification(res.message)
          }
        }
        ,err=>{
          this.openNotification(err.toString())
        })
    }

    handlePassword=(e)=>{
      this.setState({
        password:e.target.value
      })
    }

    handleUsername=(e)=>{
      this.setState({
        username:e.target.value
      })
    }

    openNotification = (desc) => {
      notification.info({
        message: `提示`,
        description: desc,
        placement:'top',
      });
    }
  

    render(){
        return(
          [
            <Header key="header" isFirstScreen={this.state.isFirstScreen} isMobile={this.state.isMobile} />,
            <Layout key="content" className="layout">

            <Content style={{ padding: '250px 50px' }}>
            <Form 
      name="basic"
      labelCol={{
        span: 2,
      }}
      wrapperCol={{
        span: 4,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={this.login.bind(this)}
      onFinishFailed={this.onFinishFailed}
      autoComplete="off"
      
    >
      <Form.Item
        label="账号"
        name="username"
        justify="center"
        rules={[
          {
            required: true,
            message: '请输入用户名!',
          },
        ]}
      >
        <Input value={this.state.username} onChange={this.handleUsername.bind(this)} />
      </Form.Item>

      <Form.Item
        label="密码"
        name="password"
        justify="center"
        rules={[
          {
            required: true,
            message: '请输入密码!',
          },
        ]}
      >
        <Input.Password value={this.state.password} onChange={this.handlePassword.bind(this)} />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        justify="center"
        wrapperCol={{
          offset: 2,
          span: 4,
        }}
      >
        <Checkbox>记住账号</Checkbox>
      </Form.Item>

      <Form.Item
      justify="center"
        wrapperCol={{
          offset: 4,
          span: 6,
        }}
      >
        <Button type="primary" htmlType="submit">
          登入
        </Button>
        <Button type="primary" htmlType="button" className='clean'>
          清空
        </Button>
      </Form.Item>
    </Form>
            </Content>
          </Layout>,
          ]

        )
    }
}

export default withRouter(Login);

