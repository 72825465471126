import { STOCK_QUERY_TRADE,STOCK_DETAIL,STOCK_QUERY_COLLECT, STOCK_ADD_COLLECT, STOCK_ADD_TRADE, STOCK_QUERY_CONVERT, STOCK_ADD_CONVERT, STOCK_QUERY_SIMPLE_BATCH, STOCK_AUDIT_TRADE, STOCK_ADD_REVOKE, STOCK_BATCH_QRCODE, STOCK_QUERY_ORDER } from "../constants/stock";

const initialState = {
    
}

export default function stock(state=initialState,action){
    switch(action.type){
        case STOCK_QUERY_TRADE:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_QUERY_COLLECT:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_QUERY_ORDER:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_ADD_COLLECT:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_ADD_TRADE:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_ADD_CONVERT:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_ADD_REVOKE:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_AUDIT_TRADE:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_DETAIL:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_QUERY_SIMPLE_BATCH:
            return{
                ...state,
                ...action.payload
            }
        case STOCK_BATCH_QRCODE:
            return{
                ...state,
                ...action.payload
            }
        default:
            return{
                state
            }
    }
}