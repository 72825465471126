import { SyncOutlined, CheckCircleOutlined   } from '@ant-design/icons';
import { Form, Tag, Select, Table, Layout, Breadcrumb, Divider, Space, Modal, Button, Col, Row, Input, notification, InputNumber } from 'antd';
import *  as actions from '@actions/stock'
import * as userAction from '@actions/user'
import * as goodsAction from '@actions/goods'
import React, { Component, useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../../utils/common';
import './index.css'



const { Content } = Layout;
const { Search,TextArea } = Input;
const { Option } = Select;
const reg = new RegExp("^([0-9]*\:[0-9]*,?)+$")
const EditableContext = React.createContext(null);

@connect(state => state, { ...actions, ...userAction, ...goodsAction })
class Convert extends Component {


    EditableRow = ({ index, ...props }) => {

        const [form] = Form.useForm();

        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        let [editing, setEditing] = useState(false);
        let inputRef = useRef(null);
        let form = useContext(EditableContext);

        let toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };
        let save = async () => {
            try {

                let values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };
        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };





    constructor() {
        super(...arguments)
        //this.userinfo = Common.getUserInfo()
        console.log('GET===>', this.queryType)
        //state.goodsList为每个成员新增了selectedBatches属性，用于保存被复选框选中的批次，修改时一定要跟着改
        this.state = {
            stockList: [],
            showMadeModal: false,
            goodsList: [],
            selectedStockIndex: -1,
            stockVo: {
                targetUser: { userId: 0 },
                targetGoodsId:0,
                goods: { goodsId: 0 },
                batchList: [],
                tradingVolume: 0,
                contractNo: '',
                remark: '',
                selectedValue:"未选择"
            },
            firstRaw:{name:'',rate:0},
            stockTotal:0
        }
        this.columns = [{
            title: '库存id',
            dataIndex: 'stockId',
            key: 'stock_id',
        },
        {
            title: '商品',
            dataIndex: 'goodsName',
            key: 'goods_name',
        },
        {
            title: '类型',
            dataIndex: 'opType',
            key: 'opType',
            render:(opType)=>{
                return(
                    opType==3?<Tag icon={<SyncOutlined spin />} color="processing">
                    出库加工
                  </Tag>:<Tag icon={<CheckCircleOutlined />} color="success">
        完工入库
      </Tag>
                )
            }
        },
        {
            title: '产量',
            dataIndex: 'tradingVolume',
            key: 'trading_volume',
        },
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'create_time',
        }

        ]
        this.modelName = '加工管理'

        this.subColumes = [
            {
                title: '出库记录号',
                dataIndex: 'stockId',
                key: 'stockId',
            },
            {
                title: '原料总消耗量',
                dataIndex: 'tradingVolume',
                key: 'tradingVolume',
                editable: true,
                
            },
            {
                title: '消耗明细',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '加工后原料剩余量',
                dataIndex: 'targetQoh',
                key: 'targetQoh',
            },


        ]

        this.batchColumes = [
            {
                title: '批次id',
                dataIndex: 'batchId',
                key: 'batchId',
            },
            {
                title: '批次入库量',
                dataIndex: 'tradingVolume',
                key: 'tradingVolume',
                editable: true,
            },
            {
                title: '批次余量',
                dataIndex: 'remainingVolume',
                key: 'tradingVolume',
            }
        ]



        this.editColumns = this.batchColumes.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: (row) => {
                        console.log('New ROW=======>',row)
                        if(parseInt(row.tradingVolume)>row.availableVolume){
                            this.openNotification("消耗量不能超出批次库存")
                            return
                        }
                        const newData = [...this.state.stockBatches];
                        const index = newData.findIndex((item) => row.key === item.key);
                        const item = newData[index];
                        row.remainingVolume = row.availableVolume-parseInt(row.tradingVolume)
                        newData.splice(index, 1, {
                            ...item,
                            ...row,
                        });
                        //注意：!!!修改的时候，选定项一定和子项产生了关联，所以下边的stateRowData就是修改项的上级
                        console.log('EDITING====>', this.state.selectedStockIndex, row)
                        //goodsList为每个成员新增了selectedBatches、tradingVolume属性，用于保存被复选框选中的批次，修改时一定要跟着改
                        let stateRowData = this.state.goodsList[this.state.selectedStockIndex]

                        //在当前选定goods的成分列表（包含未勾选的）里查找被修改的数据项
                        stateRowData.batchList.find((item) => { return item.batchId == row.batchId }).tradingVolume = parseInt(row.tradingVolume)
                        
                        //对goodsList[].selectedBatches[]里查找修改对应项
                        if(!('selectedBatches' in stateRowData))
                        stateRowData['selectedBatches'] = [];
                        let obj = stateRowData.selectedBatches.find(item => { return item.batchId == row.batchId })
                        if (obj !== undefined) {
                            obj.tradingVolume = parseInt(row.tradingVolume)
                            obj.remainingVolume = obj.availableVolume-  obj.tradingVolume
                            stateRowData.tradingVolume = this.calculateTargetVolume(index,stateRowData)
                        }
                        console.log('Debug cell====>',obj,stateRowData)
                        let _goods = this.state.goodsList
                        _goods[this.state.selectedStockIndex] = stateRowData
                        let vo = this.state.stockVo
                        vo.tradingVolume = stateRowData.tradingVolume
                        
                        //this.setState({goodsList:_goods})
                        //stateRowData.selectedBatches.find((item)=>{return item.batchId==row.batchId}).tradingVolume = row.tradingVolume
                        this.setState({stockVo:vo, goodsList:_goods, stockBatches: newData }, () => { console.log('****SAVING========>', this.state.goodsList) })
                        //setDataSource(newData);

                    },
                }),
            };
        });

    }

    // handleSave = (row) => {console.log(row)}

    componentDidMount() {
        this.loadData(-1,{ opTypes:'4',pageIndex:1})
        this.loadGoodsData()
        //this.loadMyGoodsList({ qoh: 0 });
    }

    openNotification = (desc) => {
        notification.info({
            message: `提示`,
            description: desc,
            placement: 'top',
        });
    }

    /**
     * 加载交易单列表
     * @param {页码} page 
     * @param {类型:all/in/out} type 
     */
    loadData = (targetIndex,options) => {
        this.props.dispatchStockQueryOrder({ isOut:0, ...options}).then(
            (res) => {
                
                if (res.code == 0) {
                    let data = res.result.records;
                    for (let i = 0; i < data.length; i++) {
                        data[i]['goodsName'] = data[i].goods.cname
                        data[i]['key'] = i
                    }
                    if(targetIndex===-1){
                        let stockVo = {
                            targetUser: { userId: 0 },
                            targetGoodsId:0,
                            goods: { goodsId: 0 },
                            batchList: [],
                            tradingVolume: 0,
                            contractNo: '',
                            remark: ''
                        }
                        this.setState({ stockList: data ,stockVo:stockVo,stockBatches:[],
                            selectedStockIndex:-1,currentRate:0,selectedValue:'未选择',
                            stockTotal:res.result.total
                        })
                    }else{
                        //加工一阶段信息，呈现原料消耗情况
                        for(let i=0;i<data.length;i++){
                            if(reg.test(data[i]['remark'])){
                                let groups = data[i]['remark'].split(',')
                                for(let j=0;j<groups.length;j++){
                                    groups[j] = '批次['+groups[j].replace(':',']:')
                                }
                                data[i]['remark'] = groups.join(',')
                            }
                        }
                        let stockList = this.state.stockList
                        stockList[targetIndex]['subStocks'] = data;
                        this.setState({ stockList: stockList})
                    }
                } else {
                    this.openNotification(res.message)
                    return {}
                    //alert(res.message)
                }
            }
        ).catch(err => { 
            this.openNotification(err.toString()) 
            return {}   
        })
    }



    /**
     * 加载所有商品列表
     * @param {页码} page 
     */
    loadGoodsData = () => {
        this.props.dispatchGoodsQueryAll({ pageIndex: 0,status:1 }).then(
            (res) => {
                if (res.code == 0) {
                    let data = res.result.records;
                    data = data.filter((item)=>{return item.type!=0})
                    data = Common.addKey(data,'tradingVolume')
                    this.setState({ goodsList: data })
                } else {
                    this.openNotification(res.message)
                }
            }
        ).catch(err => { this.openNotification(err.toString()) })
    }

    /**
     * 表格展开回调
     * @param {是否展开} expended 
     * @param {行数据} record 
     */
    handleExpand = (expended, record) => {

        console.log('EXPAND-------->',expended, record)
        if (typeof record.subStocks == 'undefined' || record.subStocks == null) {
            this.loadData(record.key,{opTypes:'3',order:record.orderNo,pageIndex:0} )

            console.log(record)
        }
    }


    /**
     * 显示卖出单填写窗口
     */
    handleShowMadeModal = () => {
        this.setState({ showMadeModal: true })
    }

    /**
     * 单行文本框回调
     * @param {事件载体} e 
     */
    handleInput = (e) => {
        let stockVo = this.state.stockVo;
        stockVo[e.target.name] = e.target.value
        this.setState({ stockVo: stockVo })
        console.log('INPUT===>', e)
    }


    /**
     * 商品选择器选择后的回调,用于拉取批次信息
     * @param {*} e 
     * @param {*} a 
     */
    handleGoodsSelected = (e, a) => {
        
        if (e !== 'none' && this.state.goodsList[e].batchList === undefined) {
            let index = parseInt(e)
            let ingredients = JSON.parse(this.state.goodsList[index].ingredients)
            if(ingredients.length<1||(!('goodsId' in ingredients[0] && 'volume' in ingredients[0])))
            {
                this.openNotification("配方数据错误，请联系管理员修改")
                return
            }
            this.setState({firstRaw:{name:this.state.goodsList[index].firstRawCname,rate:ingredients[0].volume}})
            //this.setState({convertRate:ingredients[0].volume})
            this.props.dispatchStockQuerySimpleBatch({goodsId:ingredients[0].goodsId,
            isValid:1, minRemainingVolume:0,pageIndex:0
            }).then(res=>{
            //this.props.dispatchStockDetail({ stockId: this.state.goodsList[index].stockId }).then(res => {
                if (res.code == 0) {
                    let data = this.state.goodsList
                    data[index].batchList = Common.addKey(res.result.records,'tradingVolume')
                    for(let i=0;i<data[index].batchList.length;i++)
                        data[index].batchList[i]['availableVolume'] =  data[index].batchList[i].remainingVolume
                    data[index]['sourceGoodsId'] = ingredients[0].goodsId
                    this.setState({
                        selectedValue:data[index].cname,
                        goodsList: data,
                        selectedStockIndex: index,
                        stockBatches: data[index].batchList
                    })
                }
                else {
                    this.openNotification(res.message)
                    return
                }
            }).catch(err => { console.log(err) })
        } else if (e !== 'none') {

            this.setState({
                selectedStockIndex: parseInt(e),
                stockBatches: this.state.goodsList[parseInt(e)].batchList
            })
        }
    }

    /**
     * 批次表格内的复选框选中回调
     * @param {行的key值} selectedRowKeys 
     * @param {行数据} selectedRows 
     */
    handleBatchCheckboxSelected = (selectedRowKeys, selectedRows) => {
        //这里选择的批次是作为原料的批次
        let selectedBatches = []
        for (let i = 0; i < selectedRows.length; i++) {
            selectedBatches.push({ batchId: selectedRows[i].batchId,
                tradingVolume: parseInt(selectedRows[i].tradingVolume),
                caExpired:selectedRows[i].caExpired
            })
        }
        //这里的data是目标产物的data
        let data = this.state.goodsList;
        let index = this.state.selectedStockIndex;
        data[index]['selectedBatches'] = selectedBatches
        //计算合成量
        data[index]['tradingVolume'] = this.calculateTargetVolume(index);
        let vo = this.state.stockVo
        vo.tradingVolume = data[index].tradingVolume
        //let stockVo = this.state.stockVo;
        //stockVo.batchList = selectedBatches
        this.setState({ goodsList: data ,stockVo:vo})
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    handleInputNumber = (e)=>{
        let vo = this.state.stockVo;
        vo.volume = e
        this.setState({stockVo:vo})
    }

    /**
     * 计算指定索引商品的成品量，内部有读取state操作
     * @param {商品列表内部索引值} index 
     * @returns 
     */
    calculateTargetVolume = (index,rowData)=>{
        let data = rowData===undefined?this.state.goodsList[index]:rowData
        console.log('CAL====>',data,rowData)
        let ingredients = JSON.parse(data.ingredients)
        if(ingredients.length<1||(!('goodsId' in ingredients[0] && 'volume' in ingredients[0])))
        {
            return data.selectedBatches.reduce((p,c)=>{return p+parseInt(c.tradingVolume)},0)
        }
        else{
            let sum = data.selectedBatches.reduce((p,c)=>{return p+parseInt(c.tradingVolume)},0)
            sum = Math.ceil(sum/1000*ingredients[0].volume)
            return sum
        }
    }

    closeBuyModal = () => {
        this.setState({ showMadeModal: false })
    }



    onSearch = () => {
        console.log("search")
        console.log("PARAM:", Common.getQueryString("roleId"))
    }



    /**
     * 提交交易单
     * @returns 
     */
    addMade = () => {

        //dispatchStockAddTrade
        if (this.state.selectedStockIndex < 0) {
            this.openNotification('请检查填写信息')
            return
        }
        let data = this.state.goodsList[this.state.selectedStockIndex]
        let stockVo = this.state.stockVo
        if(stockVo.tradingVolume==0){
            this.openNotification("请确定合成数量，如未勾选原料请选择！")
            return
        }
        //产物的商品id
        stockVo.targetGoodsId = data.goodsId;
        //原材料id
        stockVo.goods.goodsId = data.sourceGoodsId;
        //原料批次
        stockVo.batchList = data.selectedBatches;
        console.log('POST======>',stockVo)
        this.props.dispatchStockAddConvert(stockVo).then(res => {
            if (res.code == 0) {
                this.openNotification('添加成功')
                this.loadData(-1,{ opTypes:'4'})
                this.loadGoodsData()
                this.closeBuyModal()
            } else {
                this.openNotification('添加失败，' + res.message)
                return
            }
        }).catch(err => {
            this.openNotification('添加失败，' + err.toString())
        })

    }

    handleDownload = ()=>{
        this.props.dispatchStockExport({filename:'export.xlsx',  type:'convert'}).then(res=>{
            if(res.code===0){
              this.openNotification('下载成功')
            } else{
              this.openNotification(res.message)
            }
        }).catch(err=>{
        this.openNotification(err.toString())  
        })
    }

    render() {
        return ([

            <Layout key="content">
                <Navbar />
                <Layout >
                    <Sidebar/>
                    <Layout
                        style={{
                            padding: '0 24px 24px',
                        }}
                    >
                        <Breadcrumb
                            style={{
                                margin: '16px 0',
                            }}
                        >
                            <Breadcrumb.Item>管理平台</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider style={{ margin: '5px 0' }} />
                        <Row gutter={8}>
                            <Col span={6} ><Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleDownload.bind(this)}>导出数据</Button>
                                <Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleShowMadeModal.bind(this)}>加工转换</Button> 
                            </Col>
                            <Col span={2}> </Col>
                            <Col span={8}></Col>

                            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                               {/* <Search placeholder="输入商品名称" onSearch={this.onSearch.bind(this)} style={{ width: 200, marginRight: '8px' }} />*/}
                            </Col>
                        </Row>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: '8px',
                                margin: 0,
                                minHeight: 480,

                            }}
                        >
                            <Table
                                columns={this.columns}
                                dataSource={this.state.stockList}
                                expandedRowRender={(record, index) => {
                                    return (<Table columns={this.subColumes} dataSource={this.state.stockList[index].subStocks} pagination={{ hideOnSinglePage: true }} />)
                                }}
                                onExpand={this.handleExpand.bind(this)}
                                pagination={{
                                    showQuickJumper:true,
                                    pageSize:Common.pageSize,
                                    total:this.state.stockTotal,
                                    onChange:(pageNo, _) => this.loadData(-1,{ opTypes:'4',pageIndex:pageNo})
                  
                                  }}
                            />
                            <Modal
                                title="加工转换单录入"
                                style={{
                                    top: 20,
                                }}
                                destroyOnClose={true}
                                centered
                                visible={this.state.showMadeModal}
                                okText='提交'
                                cancelText='取消'
                                onOk={this.addMade.bind(this)}
                                onCancel={this.closeBuyModal}
                                width={600}
                                
                            >
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 18,
                                    }}
                                    layout="horizontal"

                                >
                                    <Form.Item label="选择目标商品">
                                        <Select defaultValue="未选择" value={this.state.selectedValue} style={{ width: 120 }} onChange={this.handleGoodsSelected.bind(this)} >
                                           
                                            {this.state.goodsList.map((item) => {
                                                return (<Option key={item.key} value={item.key}>{item.cname}</Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="成品数量">
                                    <InputNumber readOnly min={1}  defaultValue={1} value={this.state.stockVo.tradingVolume} onChange={this.handleInputNumber.bind(this)} />
                                    </Form.Item>
                                    <Form.Item label="转化率">
                                            <Space>每消耗1000单位 {this.state.firstRaw.name.length>0?<Tag color="green">{this.state.firstRaw.name}</Tag>:''} 可以生成所选产品{this.state.firstRaw.rate}单位  </Space>
                                    </Form.Item>


                                    <Form.Item label="原料批次">
                                        <Table rowSelection={{
                                            type: 'checkbox',
                                            ...{
                                                onChange: this.handleBatchCheckboxSelected.bind(this),
                                                getCheckboxProps: (record) => ({

                                                    name: record.name,
                                                })
                                            }
                                        }}
                                            components={{
                                                body: {
                                                    row: this.EditableRow,
                                                    cell: this.EditableCell,
                                                },
                                            }}
                                            rowClassName={() => 'editable-row'}
                                            scroll={{ x: '100%' }}
                                            columns={this.editColumns}
                                            dataSource={this.state.stockBatches}
                                            pagination={{ hideOnSinglePage: true }} />
                                    </Form.Item>
                                    <Form.Item label='备注'>
                                    <TextArea name='remark' rows={3} placeholder="最多100个汉字" maxLength={256} value={this.state.stockVo.remark} onChange={this.handleInput.bind(this)}/>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </Content>
                    </Layout>
                </Layout>
                <Bottom />
            </Layout>,

        ]
        )
    }
}
export default withRouter(Convert)