import { Breadcrumb, Layout, Col, Row,Tag,Table,notification} from 'antd';
import { connect } from 'react-redux'
import React,{ Component, useState, useEffect  }from 'react'
import { withRouter } from 'react-router';
import { USER_TOKEN } from '@constants/user'
import 'antd/dist/antd.css'
import './index.css'
import *  as actions from '@actions/stock'
import * as statActions from '@actions/stat'
import * as goodsActions from '@actions/goods'
import { Line } from '@ant-design/charts';
import { Pie,Column } from '@ant-design/plots';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Common from '../../utils/common';


const {  Content,Footer} = Layout;

const columnData = [
 
];
const columnConfig = {
  data:columnData,
  height:300,/*  */
  xField: 'cname',
  yField: 'sumTradingVolume',
  label: {
    // 可手动配置 label 数据标签位置
    position: 'middle',
    // 'top', 'bottom', 'middle',
    // 配置样式
    style: {
      fill: '#FFFFFF',
      opacity: 0.6,
    },
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
    },
  },
  meta: {
    type: {
      alias: '类别',
    },
    sales: {
      alias: '销售额',
    },
  },
};


const data = [

];
const config = {
  data:data,
  height: 200,
  xField: 'cname',
  yField: 'sumTradingVolume',
  point: {
    size: 5,
    shape: 'diamond',
  }
}

const pieData = []
const pieConfig = {
  data:pieData,
  height:300,
  appendPadding: 10,
  angleField: 'sumTradingVolume',
  colorField: 'cname',
  radius: 0.9,
  label: {
    type: 'inner',
    offset: '-30%',
    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: 'center',
    },
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
}
console.log('pre--->',config)

@connect(state=>state.stock, {...actions,...statActions,...goodsActions})
class Dashboard extends Component{
    constructor(){
        super(...arguments)
        this.state = {
            buyStockList:[],
            soldStockList:[],
            pieData:[],
            pieRight:[],
        }
        this.columns = [{
          title: '库存id',
          dataIndex: 'stockId',
          key: 'stock_id',
        },
        {
          title: '卖家',
          dataIndex: 'sourceUsername',
          key: 'source_username',
        },
        {
          title: '买家',
          dataIndex: 'targetUsername',
          key: 'target_username',
        },
        {
          title: '商品',
          dataIndex: 'goodsName',
          key: 'goods_name',
        },
        {
          title: '合同号',
          dataIndex: 'contractNo',
          key: 'contract_no',
        },
        {
          title: '数量',
          dataIndex: 'tradingVolume',
          key: 'trading_volume',
        },
        {
          title: '时间',
          dataIndex: 'createTime',
          key: 'create_time',
        },
        {
          title: '状态',
          key: 'is_valid',
          render:(_,record)=>{
            return(
              record.opType==2?<Tag color="magenta">待确认</Tag>: record.opType==5?<Tag color="green">已确认</Tag>:<Tag color="geekblue">已撤销</Tag>
            )
          }
        },]
        this.goodsList = []

        this.configPieLeft = {
          height:300,
          appendPadding: 10,
          data:this.state.pieLeft,
          angleField: 'sales',
          colorField: 'type',
          radius: 0.9,
          label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
              fontSize: 14,
              textAlign: 'center',
            },
          },
          interactions: [
            {
              type: 'element-active',
            },
          ],
        }

        this.cfg = {
          height: 200,
          xField: 'year',
          yField: 'value',
          point: {
            size: 5,
            shape: 'diamond',
          }
        }
    }

    componentDidMount(){
      this.loadChartData()
      this.loadData(1,'buy')
      this.loadData(1,'sold')
    }

    openNotification = (desc) => {
      notification.info({
        message: `提示`,
        description: desc,
        placement: 'top',
      });
    }

    loadChartData = ()=>{
      this.props.dispatchGoodsQueryAll({ pageIndex: 0 }).then(
        (res) => {
            if (res.code === 0) {
                let data = res.result.records;
                this.goodsList = Common.addKey(data);
                
                //加载图表数据
                console.log('READY FOR-->STAT')
                this.props.dispatchStatStockSimple({groupBy:'goods_id'}).then(r=>{
                  if(r.code===0){
                    console.log('READY FOR-->STAT-1',r)
                    for(let i=0;i<r.result.length;i++){
                      let goods = this.goodsList.find((t,m,n)=>{return t.goodsId===r.result[i].goodsId})
                      r.result[i]['cname'] = goods==undefined?'未知':goods.cname
                    }
                   
                    config.data = r.result
                    pieConfig.data = r.result
                    columnConfig.data = r.result
                    console.log('FINNAL------->',config)
                    //configPieLeft.data =r.result
                    this.setState({pieData:r.result},()=>{console.log('FINNAL------->',this.state.pieLeft)})
                  }else{
                    this.openNotification(r.message)
                  }
                }).catch(e=>{this.openNotification(e.toString())})
            } else {
                this.openNotification(res.message)
            }
        }
    ).catch(err => { this.openNotification('C'+err.toString()) })
    }

      /**
   * 加载交易单列表
   * @param {页码} page 
   * @param {类型:all/in/out} type 
   */
  loadData = (page,type) => {
    this.props.dispatchStockQueryTrade({ pageIndex: page, type: type}).then(
      (res) => {
        if (res.code == 0) {
          let data = res.result.records;
          for (let i = 0; i < data.length; i++) {
            data[i]['sourceUsername'] = data[i].sourceUser.username
            data[i]['targetUsername'] = data[i].targetUser.username
            data[i]['goodsName'] = data[i].goods.cname
            data[i]['key'] = i
          }
          if(type==='buy')
            this.setState({ buyStockList: data,buyStockTotal:res.result.total })
          else
          this.setState({ soldStockList: data,soldStockTotal:res.result.total })

          } else {
          alert(res.message)
        }
      }
    ).catch(err => { alert(err.toString()) })
  }


    onFinish = (values) => {
      console.log('Success:', values);
    }

    onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }
  

    render(){
        return(
          [
            
            <Layout key="content">
              <Navbar />
            <Layout >
            <Sidebar />
      <Layout
        style={{
          padding: '0 24px 24px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>首页</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 480,

          }}
        >
          <Row>
            <Col span={12}>
            <Line {...config} />
            </Col>
            <Col span={12}>
            <Line {...config} />
            </Col>
          </Row>
          <Row style={{margin:'40px'}}>
            <Col span={12}>
            <Column {...columnConfig} />
            </Col>
            <Col span={12}>
            <Pie {...pieConfig}  />
                        </Col>
            </Row> 
            <Row style={{padding:'10px',fontSize:'18px',fontWeight:600,backgroundColor:'#e0e0e0',borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}>
              <Col span={24} >
                我的买入
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              <Table
                columns={this.columns}
                dataSource={this.state.buyStockList}
                pagination={{
                  showQuickJumper:true,
                  pageSize:Common.pageSize,
                  total:this.state.buyStockTotal,
                  onChange:(pageNo, _) => this.loadData(pageNo,'buy')

                }}
              />
              </Col>
            </Row>
            <Row style={{padding:'10px',fontSize:'18px',fontWeight:600,backgroundColor:'#e0e0e0',borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}>
              <Col span={24} >
                我的卖出
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              <Table
                columns={this.columns}
                dataSource={this.state.soldStockList}
                pagination={{
                  showQuickJumper:true,
                  pageSize:Common.pageSize,
                  total:this.state.soldStockTotal,
                  onChange:(pageNo, _) => this.loadData(pageNo,'sold')

                }}
              />
              </Col>
            </Row>
        </Content>
      </Layout>
    </Layout>
    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
          </Layout>,
          ]
          
        )
    }
}

export default withRouter(Dashboard);

