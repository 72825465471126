import { Steps, Layout,Input ,Button, Checkbox, Form,Alert,Avatar, List ,notification,Result} from 'antd';
import { connect } from 'react-redux'
import React,{ Component } from 'react'
import * as actions from '@actions/user'
import { USER_TOKEN } from '@constants/user'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { withRouter } from 'react-router';
import 'antd/dist/antd.css'
import './index.css'
import Header from '@components/header';

const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
const regUsername = /^\w{5,12}$/;
const {  Content } = Layout;
const { Step } = Steps;
const data = [
    {
        index:1,
      title: '收集点',
      description:'收集农户和农场的基础农作物',
      code:64
    },
    {
        index:2,
      title: '加工厂',
      description:'对加工点或其他加工厂生产的商品进行加工',
      code:16
    },
    {
        index:3,
      title: '零售商',
      description:'把采购自加工厂的产品进行零售',
      code:8
    },
  ];

  Array.prototype.remove = function(val) { 
    var index = this.indexOf(val); 
    if (index > -1) { 
    this.splice(index, 1); 
    } 
    };

@connect(state=>state.user, {...actions})
class Register extends Component{
    
    constructor(){
        super(...arguments)

        this.state = {
            test:1,
            currentStep:0,
            selectedCode:[],
            params:{
              username:'',
              password:'',
              password_repeat:'',
              realName:'',
              email:'',
              mobile:'',
              orgName:'',
              orgCode:'',
              cop:'',
              country:'中国',
              province:'',
              city:'',
              county:'',
              address:'',
              issuerUid:2
            },
            showResult:false,
            result:{
              code:0,
              message:''
            }

        }
        this.permitCode = 0;
    }

    onFinish = (values) => {
      console.log('Success:', values);
    }

    onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }

    itemSelected = (code)=>{
      console.log( this.state.selectedCode);
      let selected = this.state.selectedCode;
      if(selected.indexOf(code)>=0){
        selected.remove(code);
      } else{
        selected.push(code);
      }
        this.setState({selectedCode:selected},()=>{console.log(this.state.selectedCode)});

    }
  
    register = ()=>{
      let user = this.state.params;
      user['permitCode'] = this.permitCode;
      console.log('USER=>',user)
      this.props.dispatchUserRegister(user).then(res=>{
        this.setState({showResult:true,
        result:res
        })
    },err=>{
      this.setState({showResult:true})
      this.setState({result:{code:500,message:err.toString()}})
    })
  }

    nextStep = (step)=>{
      if(step===2){
        if(this.state.selectedCode.length==0){
          alert("请选择角色！");
          return;
        }
        this.permitCode = this.state.selectedCode.reduce((x,y)=>x|y,0);
      } else if(step ===3){
       //this. checkRepeatUsername();
        //检查参数
        if(!this.checkInput())
        return;
      } else if(step==4){
        //检查参数
        if(!this.checkAdvInput())
        return;
        //注册并跳转
        this.register()
        
      }
      this.setState({currentStep:(step-1)>2?2:(step-1)});
    }

    handleInput = (value) => {
      let key = value.target.name
      let params = this.state.params;
      params[key] = value.target.value;
      this.setState({params:params});
    }

    handleCheckbox = (e) =>{
      let params = this.state.params;
      params.issuerUid = e.target.checked?2:0
      this.setState({params:params})
    }

    handleFocus = (value)=>{

    }

    checkRepeatUsername = ()=>{
      //      this.props.dispatchUserLogin(form).then(res=>{console.log(res)},err=>{console.log(err)})
      this.props.dispatchUserCheck({username:this.state.params.username}).then(res=>{console.log(res)},err=>{console.log(err)})
    }

    /**
     * 校验基础信息
     * @returns 
     */
    checkInput = () => {
              //校验合法性
              if(!regUsername.test(this.state.params.username)){
                alert('用户名只能用字母数字下划线组合');
                return false;
              }
              if(!regEmail.test(this.state.params.email)){
                alert('邮箱格式错误!');
                return false;
              }
              if(this.state.params.realName.trim().length===0){
                alert('姓名必须填写!');
                return false;
              }
              if(!regMobile.test(this.state.params.mobile)){
                alert('手机号格式错误');
                return false;
              }
              if(this.state.params.realName.trim().length==0||this.state.params.password.trim().length==0){
                alert('所有表单都需要填写！');
                return false;
              }
              if(this.state.params.password.length<6){
                alert('密码长度需要大于5！');
                return false;
              }
              if(this.state.params.password!=this.state.params.password_repeat){
                alert('密码必须一致！');
                return false;
              }
              return true;
    }

    /**
     * 校验扩展信息
     * @returns 
     */
    checkAdvInput = ()=>{
      if((this.permitCode&16==16)&&this.state.params.cop.trim().length==0){
        alert('生产许可证需要填写，或者权限去掉生产相关')
        return false;
      }
      if(this.state.params.orgName.trim().length==0||
        this.state.params.orgCode.trim().length==0||
        this.state.params.country.trim().length==0||
        this.state.params.province.trim().length==0||
        this.state.params.city.trim().length==0||
        this.state.params.county.trim().length==0||
        this.state.params.address.trim().length==0)
      {
        alert('所有信息都要填写！')
        return false;
      }
      return true;
      
    }

    goTo = (url)=>{
      console.log('goto'+url)
      this.props.history.push(url)
    }

    openNotification = (desc) => {
      notification.info({
        message: `提示`,
        description: desc,
        placement:'top',
      });
    }

    render(){
        return(
          [
            <Header key="header" isFirstScreen={this.state.isFirstScreen} isMobile={this.state.isMobile} />,
            <Layout key="content" className="layout">
<Content style={{background:'#ffffff'}}>
<Steps current={this.state.currentStep} style={{padding:'150px 150px 15px 150px'}}>
    <Step title="角色选择" description="选择您所属团体的角色" />
    <Step title="基本信息" description="填写个人基本信息" />
    <Step title="机构信息" description="填写组织机构相关信息" />
  </Steps>
  {this.state.currentStep===0?
            <Content className='contentFrame'>
            
  <Alert message="作为农户注册可以享受以下政策。作为农户注册可以享受以下政策。作为农户注册可以享受以下政策。作为农户注册可以享受以下政策。作为农户注册可以享受以下政策。作为农户注册可以享" type="info" showIcon />

  <List
    style={{margin:'30px'}}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item className={ this.state.selectedCode.indexOf(item.code)>=0 ?'highlightItem':'normalItem'} onClick={this.itemSelected.bind(this,item.code)}>
        <List.Item.Meta
          avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
      )}
      /><div className='btnContainer'>
      <Button onClick={this.nextStep.bind(this,2)} type="primary" shape="round">下一步</Button></div>
            </Content>
            :this.state .currentStep===1? 
            <Content className='contentFrame'> 
            <Form labelCol={{ span: 6,}} wrapperCol={{ span: 10, }}      layout="horizontal"     initialValues={{       size: 'default',    }}    size='default' >
      <Form.Item label="用户名"> <Input maxLength={12} placeholder="用户名使用英文字母数字，长度不超过12个字" name="username" value = {this.state.params.username} onChange = {this.handleInput.bind(this)}   /></Form.Item>
      <Form.Item label="密码"> <Input.Password minLength={6} maxLength={12} placeholder="请输入密码，长度6-12位"   iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}  name="password"  value = {this.state.params.password} onChange = {this.handleInput.bind(this)} /></Form.Item>
      <Form.Item label="确认密码"><Input.Password minLength={6} maxLength={12} placeholder="请再次输入上述密码" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} name="password_repeat"  value = {this.state.params.password_repeat} onChange = {this.handleInput.bind(this)}  /> </Form.Item>
      <Form.Item label="姓名"> <Input name="realName" maxLength={12}  placeholder="姓名长度不超过12个字"  value = {this.state.params.realName} onChange = {this.handleInput.bind(this)} /></Form.Item>
      <Form.Item label="手机号">  <Input name="mobile"  maxLength={11} placeholder="号码长度不超过11个数字"  value = {this.state.params.mobile} onChange = {this.handleInput.bind(this)} /></Form.Item>
      <Form.Item label="邮箱"> <Input name="email" maxLength={18}   value = {this.state.params.email} onChange = {this.handleInput.bind(this)} /> </Form.Item>
    </Form>
                <div className='btnContainer'> <Button onClick={this.nextStep.bind(this,3)} type="primary" shape="round">下一步</Button></div>
            </Content>
              :
              this.state.showResult===false?
              <Content className='contentFrame'>
               
 <Form labelCol={{ span: 6,}} wrapperCol={{ span: 10, }}      layout="horizontal"     initialValues={{       size: 'default',    }}    size='default' >
      <Form.Item label="组织机构名称"><Input name="orgName" minLength={2} maxLength={30} value = {this.state.params.orgName} onChange = {this.handleInput.bind(this)}   /></Form.Item>
      <Form.Item label="组织机构代码"> <Input  name="orgCode" minLength={9} maxLength={9}  value = {this.state.params.orgCode} onChange = {this.handleInput.bind(this)} /></Form.Item>
      {this.permitCode&16===16?
      <Form.Item label="生产许可证编号"><Input name="cop" minLength={9} maxLength={14}   value = {this.state.params.cop} onChange = {this.handleInput.bind(this)}  /> </Form.Item>:''
    }
      <Form.Item label="省"> <Input name="province" minLength={2} maxLength={20}  value = {this.state.params.province} onChange = {this.handleInput.bind(this)} /></Form.Item>
      <Form.Item label="市">  <Input name="city" minLength={2} maxLength={30}  value = {this.state.params.city} onChange = {this.handleInput.bind(this)} /></Form.Item>
      <Form.Item label="区/县"> <Input name="county" minLength={2} maxLength={30}   value = {this.state.params.county} onChange = {this.handleInput.bind(this)} /> </Form.Item>
      <Form.Item label="地址"> <Input name="address" minLength={2} maxLength={40}   value = {this.state.params.address} onChange = {this.handleInput.bind(this)} /> </Form.Item>
      <Form.Item label="认证机构"><Checkbox checked={this.state.params.issuerUid==2}  onChange={this.handleCheckbox.bind(this)}>国检集团认证</Checkbox></Form.Item>
    </Form>
                <div className='btnContainer'> <Button onClick={this.nextStep.bind(this,4)} type="primary" shape="round">完成</Button></div>:''
              </Content>:
              <Content className='contentFrame'>
                <Result
    status={this.state.result.code==0?'success':'error'}
    title={this.state.result.message}
    subTitle=""
    extra={[
      <Button type="primary" key="console" onClick={this.goTo.bind(this,'/')}>
        回到首页
      </Button>
    ]}
  />
                </Content>
            }
            </Content>
          </Layout>,
          ]

        )
    }
}

export default withRouter(Register);

