import {  Tag, Select, Table, Layout, Breadcrumb, Divider, Space, Modal, Button, Col, Row, Input,notification ,Image} from 'antd';
import *  as actions from '@actions/stock'
import * as userAction from '@actions/user'
import * as goodsAction from '@actions/goods'
import React, { Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../../utils/common';
import './index.css'



const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const EditableContext = React.createContext(null);

@connect(state => state, { ...actions, ...userAction, ...goodsAction })
class Retail extends Component {

  constructor() {
    super(...arguments)
    //this.userinfo = Common.getUserInfo()
     //state.goodsList为每个成员新增了selectedBatches属性，用于保存被复选框选中的批次，修改时一定要跟着改
    this.state = {
      stockList: [],
      goodsList: [],
      soldOrderExpanded: false,
      stockBatches: [],
      showModel:false,
      qrcodeUrl:''
    }
    this.selectedBatch = []

    this.columns = [{
      title: '库存id',
      dataIndex: 'stockId',
      key: 'stock_id',
    },
    {
      title: '卖家',
      dataIndex: 'sourceUsername',
      key: 'source_username',
    },
    {
      title: '买家',
      dataIndex: 'targetUsername',
      key: 'target_username',
    },
    {
      title: '商品',
      dataIndex: 'goodsName',
      key: 'goods_name',
    },
    {
      title: '合同号',
      dataIndex: 'contractNo',
      key: 'contract_no',
    },
    {
      title: '数量',
      dataIndex: 'tradingVolume',
      key: 'trading_volume',
    },
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'create_time',
    },
    {
      title: '状态',
      key: 'is_valid',
      render:(_,record)=>{
        return(
          record.opType==2?<Tag color="magenta">待确认</Tag>: record.opType==5?<Tag color="green">已确认</Tag>:<Tag color="geekblue">已撤销</Tag>
        )
      }
    },


    ]
    this.modelName = '零售管理'

    this.goodsColumns = [
      {
        title: '库存编号',
        dataIndex: 'stockId',
        key: 'stockId',
      },
      {
        title: '名称',
        dataIndex: 'cname',
        key: 'cname',
      },
      {
        title: '可用库存',
        dataIndex: 'qoh',
        key: 'qoh',
      },
    ]
    this.subColumes = [
      {
        title: '批次id',
        dataIndex: 'batchId',
        key: 'batchId',
      },
      {
        title: '批次入库量',
        dataIndex: 'tradingVolume',
        key: 'tradingVolume',
        editable: true,
      },
      {
        title: '批次余量',
        dataIndex: 'remainingVolume',
        key: 'tradingVolume',
      },
      {
        title: '溯源码',
        key: 'qrcode',
        render:(_,record)=>{
          return (<Space><a onClick={this.showQRCode.bind(this,record)}>查看</a></Space>)
          }
        }
    ]
  }

  // handleSave = (row) => {console.log(row)}

  componentDidMount() {
    this.loadData(1)


  }

  openNotification = (desc) => {
    notification.info({
      message: `提示`,
      description: desc,
      placement:'top',
    });
  }

  /**
   * 加载交易单列表
   * @param {页码} page 
   * @param {类型:all/buy/sold} type 
   */
  loadData = (page) => {
    this.props.dispatchStockQueryTrade({ pageIndex: page, type: 'retail' }).then(
      (res) => {
        if (res.code == 0) {
          let data = res.result.records;
          for (let i = 0; i < data.length; i++) {
            data[i]['sourceUsername'] = data[i].sourceUser.username
            data[i]['targetUsername'] = data[i].targetUser.username
            data[i]['goodsName'] = data[i].goods.cname
            data[i]['key'] = i
          }
          this.setState({ stockList: data })
        } else {
          alert(res.message)
        }
      }
    ).catch(err => { alert(err.toString()) })
  }

  loadMyGoodsList = (query) => {
    this.props.dispatchGoodsMyAll({ pageIndex: 0, ...query }).then(res => {
      if (res.code == 0) {
        let data = []
        for (let i = 0; i < res.result.records.length; i++) {
          res.result.records[i]['key'] = i
          res.result.records[i]['cname'] = res.result.records[i].goods.cname
          res.result.records[i]['selectedBatches'] = []
        }
        this.setState({ goodsList: res.result.records }, () => { console.log('GOD=====>', this.state.goodsList) })
      } else {
        alert(res.message);
      }
    }).catch(err => {
      alert(err.toString());
    })
  }

  showQRCode =(record)=>{
    console.log('SHOW=====>',record)
    this.setState({showModel:true,qrcodeUrl:'https://www.tracedxal.com/api/stock/qrcode?batchId='+record.batchId})
  }

  closeQRModel = ()=>{
    this.setState({showModel:false})
  }

  printQRCode =()=>{
    window.open(this.state.qrcodeUrl,'打印溯源码')
  }
  /**
   * 表格展开回调
   * @param {展开目标} targer
   * @param {是否展开} expended 
   * @param {行数据} record 
   */
  handleExpand = (target, expended, record) => {

    if (typeof record.batchList == 'undefined' || record.batchList == null) {
      console.log('handleExpand=====>', expended, record, target)
      this.props.dispatchStockDetail({ stockId: record.stockId })
        .then((res) => {
          console.log('handleExpand=====>', expended)
          if (res.code == 0) {
            let stockList = []
            if (target === 'goods')
              stockList = this.state.goodsList
            else
              stockList = this.state.stockList

            if (res.result == null) {
              res.result = []
              res.result['batchList'] = []
            }
            stockList[record.key].batchList = res.result.batchList
            for (let j = 0; j < stockList[record.key].batchList.length; j++) {
              stockList[record.key].batchList[j]['key'] = stockList[record.key].batchList[j]['batchId']
            }
            if (target === 'goods') {
              this.setState({ goodsList: stockList }, console.log(this.state.stockList[record.key]))
            } else {
              this.setState({ stockList: stockList }, console.log(this.state.stockList[record.key]))
            }
          }
        })
      console.log(record)
    }
  }





  onSearch = () => {
    console.log("search")
    console.log("PARAM:", Common.getQueryString("roleId"))
  }

  export =()=>{
    this.openNotification("请从我的买入导出")
  }

  render() {
    return ([

      <Layout key="content">
        <Navbar />
        <Layout >
          <Sidebar/>
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>管理平台</Breadcrumb.Item>
              <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
            </Breadcrumb>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={8}>
              <Col span={6} ><Button type="primary" style={{ marginLeft: '8px' }} onClick={this.export.bind(this)}>导出数据</Button>
                {this.state.queryType == 'sold' ? <Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleShowBuyModal.bind(this)}>我要卖出</Button> : ''}
              </Col>
              <Col span={2}> </Col>
              <Col span={8}></Col>

              <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/*<Search placeholder="输入组织名称" onSearch={this.onSearch.bind(this)} style={{ width: 200, marginRight: '8px' }} />*/}
              </Col>
            </Row>
            <Content
              className="site-layout-background"
              style={{
                padding: '8px',
                margin: 0,
                minHeight: 480,

              }}
            >
              <Table
                columns={this.columns}
                dataSource={this.state.stockList}
                expandedRowRender={(record, index) => {
                  return (<Table columns={this.subColumes} dataSource={this.state.stockList[index].batchList} pagination={{ hideOnSinglePage: true }} />)
                }}
                onExpand={this.handleExpand.bind(this, 'stock')}
              />
            
              <Modal title="追溯二维码" visible={this.state.showModel}  okText='打印'  onOk={this.printQRCode.bind(this)}  cancelText='关闭' onCancel={this.closeQRModel.bind(this)} >
                <Image src={this.state.qrcodeUrl}/>
              </Modal>

            </Content>
          </Layout>
        </Layout>
        <Bottom />
      </Layout>,

    ]
    )
  }
}
export default withRouter(Retail)