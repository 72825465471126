import { Form,  Select, InputNumber, Tag, Table, Layout, Breadcrumb, Divider, Space, Modal, Button, Col, Row, Input, notification } from 'antd';
import *  as actions from '@actions/stock'
import * as userAction from '@actions/user'
import * as goodsAction from '@actions/goods'
import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../../utils/common';
import './index.css'



const { Content } = Layout;
const { Search, TextArea } = Input;
const { Option } = Select;


@connect(state => state, { ...actions, ...userAction, ...goodsAction })
class Revoke extends Component {

  constructor() {
    super(...arguments)
    this.state = {
      showRevokeModal: false,
      goodsList: [],
      stockVo: {
        stockId: 0,
        tradingVolume: 1,
        remark: '',
        batchList: []
      },
      min: 0,
      max: 0,
      goodsTotal:0

    }
    this.currentBatch = {}
    this.columns = [
      {
        title: '商品id',
        dataIndex: 'goodsId',
        key: 'goodsId',
      },
      {
        title: '商品',
        dataIndex: 'cname',
        key: 'cname',
      },
      {
        title: '累计入库',
        dataIndex: 'sumTradingVolume',
        key: 'sumTradingVolume',
      },
      {
        title: '剩余总量',
        dataIndex: 'sumRemainingVolume',
        key: 'sumRemainingVolume',
      }
    ]


    this.subColumes = [
      {
        title: '批次id',
        dataIndex: 'batchId',
        key: 'batchId',
      },
      {
        title: '批次入库量',
        dataIndex: 'tradingVolume',
        key: 'tradingVolume',
        editable: true,
      },
      {
        title: '批次余量',
        dataIndex: 'remainingVolume',
        key: 'tradingVolume',
      },
      {
        title: '类型',
        dataIndex: 'opType',
        key: 'opType',
        render:(opType)=>{
          return (opType===1?<Tag color="#87d068">收集</Tag>:opType===4?<Tag color="#f50">加工</Tag>:<Tag  color="#2db7f5">交易</Tag>)
        }
      },
      {
        title: '操作',
        key: 'action',
        render: (row) => {
          console.log('delete===>', row.batchId, row.remainingVolume)
          return (
            <a onClick={this.handleShowRevokeModal.bind(this, row)}>移除</a>
          )
        }
      }
    ]
    this.modelName = '移除管理'
  }


  componentDidMount() {
    this.loadData(1)
  }

  openNotification = (desc) => {
    notification.info({
      message: `提示`,
      description: desc,
      placement: 'top',
    });
  }

  revoke = (batchid, volume) => {
    this.openNotification("待开发")
  }

  /**
   * 加载库存列表
   * @param {页码} page 
   * @param {类型:all/in/out} type 
   */
  loadData = (page) => {
    this.props.dispatchGoodsQueryBatchQoh({ pageIndex: page }).then(
      (res) => {
        if (res.code == 0) {
          let data = Common.addKey(res.result.records);
          this.setState({ goodsList: data,goodsTotal:res.result.total })
        } else {
          this.openNotification(res.message)
        }
      }
    ).catch(err => { this.openNotification(err.toString()) })
  }



  addRevoke = () => {
    if (this.state.stockVo.tradingVolume > this.currentBatch.remainingVolume || this.state.stockVo.tradingVolume < 0) {
      this.openNotification('请检查填写信息')
      return
    }
    let vo = this.state.stockVo
    this.currentBatch.tradingVolume = vo.tradingVolume
    vo.batchList = [this.currentBatch]
    vo.goodsId = this.currentBatch.goodsId
    vo.goods = {goodsId:this.currentBatch.goodsId}
    console.log(vo)
   
      this.props.dispatchStockAddRevoke(vo).then(res => {
        if (res.code == 0) {
          this.openNotification('添加成功')
          this.loadData(1)
          this.closeRevokeModal()
        } else {
          this.openNotification('添加失败，' + res.message)
          return
        }
      }).catch(err => {
        this.openNotification('添加失败，' + err.toString())
      })
  }

  /**
   * 显示卖出单填写窗口
   */
  handleShowRevokeModal = (record) => {
    console.log('Row data===>', record)
    this.currentBatch = record
    let vo = this.state.stockVo
    vo.tradingVolume = record.remainingVolume
    this.setState({ min: 0, max: record.remainingVolume, showRevokeModal: true ,stockVo:vo})
  }

  closeRevokeModal = () => {
    this.setState({ showRevokeModal: false })
  }
  /**
   * 单行文本框回调
   * @param {事件载体} e 
   */
  handleInput = (e) => {
    let vo = this.state.stockVo;
    vo[e.target.name] = e.target.value
    this.setState({ stockVo: vo })
  }

  handleInputNumber = (e) => {
    if(e>this.currentBatch.remainingVolume){
      this.openNotification("移除数量不能超过剩余量")
      return
    }
    let vo = this.state.stockVo;
    vo.tradingVolume = e
    this.setState({ stockVo: vo })
  }




  /**
* 表格展开回调
* @param {是否展开} expended 
* @param {行数据} record 
*/
  handleExpand = (expended, record) => {

    if (typeof record.batchList == 'undefined' || record.batchList == null) {
      this.props.dispatchStockQuerySimpleBatch({
        goodsId: record.goodsId,
        isValid: 1, pageIndex: 0,opTypes:'1,4,5'
      })
        .then((res) => {
          if (res.code == 0) {
            let goodsList = this.state.goodsList

            if (res.result.records === null) {
              res.result.records = []
            }
            goodsList[record.key].batchList = res.result.records
            console.log('RECORD====>', goodsList)
            for (let j = 0; j < goodsList[record.key].batchList.length; j++) {
              goodsList[record.key].batchList[j]['key'] = goodsList[record.key].batchList[j]['batchId']
              goodsList[record.key].batchList[j]['goodsId'] = record.goodsId
            }

            this.setState({ goodsList: goodsList }, () => { console.log(this.state.goodsList[record.key]) })
          }
        }).catch(err => {
          console.log(err)
        })
      console.log(record)
    }
  }

  onSearch = () => {
    console.log("search")
    console.log("PARAM:", Common.getQueryString("roleId"))
  }

  addKey = (arr) => {
    for (let i = 0; i < arr.length; i++)
      arr[i]['key'] = i
    return arr
  }

  handleDownload = ()=>{
    this.props.dispatchStockExport({filename:'export.xlsx',  type:'revoke'}).then(res=>{
        if(res.code===0){
          this.openNotification('下载成功')
        } else{
          this.openNotification(res.message)
        }
    }).catch(err=>{
    this.openNotification(err.toString())  
    })
}

  render() {
    return ([

      <Layout key="content">
        <Navbar />
        <Layout >
          <Sidebar />
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>管理平台</Breadcrumb.Item>
              <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
            </Breadcrumb>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={8}>
              <Col span={6} ><Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleDownload.bind(this)}>导出数据</Button>

              </Col>
              <Col span={2}> </Col>
              <Col span={8}></Col>

              <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/*<Search placeholder="输入商品名称" onSearch={this.onSearch.bind(this)} style={{ width: 200, marginRight: '8px' }} />*/}
              </Col>
            </Row>
            <Content
              className="site-layout-background"
              style={{
                padding: '8px',
                margin: 0,
                minHeight: 480,

              }}
            >
              <Table
                columns={this.columns}
                dataSource={this.state.goodsList}
                expandedRowRender={(record, index) => {
                  return (<Table columns={this.subColumes} dataSource={this.state.goodsList[index].batchList} pagination={{ hideOnSinglePage: true }} />)
                }}
                onExpand={this.handleExpand.bind(this)}
                pagination={{
                  showQuickJumper:true,
                  pageSize:Common.pageSize,
                  total:this.state.goodsTotal,
                  onChange:(pageNo, _) => this.loadData(pageNo)
                }}
              />
              <Modal
                title="填写移除单"
                style={{
                  top: 20,
                }}
                centered
                visible={this.state.showRevokeModal}
                okText='提交'
                cancelText='取消'
                onOk={this.addRevoke.bind(this)}
                onCancel={this.closeRevokeModal.bind(this)}
                width={600}
              >
                <Form
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 18,
                  }}
                  layout="horizontal"

                >

                  <Form.Item label="移除数量">
                    <InputNumber min={this.state.min} max={this.state.max} defaultValue={this.state.max} value={this.state.stockVo.tradingVolume} onChange={this.handleInputNumber.bind(this)} />
                  </Form.Item>

                  <Form.Item label='原因'>
                    <TextArea name='remark' rows={3} placeholder="填写移除原因" maxLength={256} value={this.state.stockVo.remark} onChange={this.handleInput.bind(this)} />
                  </Form.Item>
                </Form>
              </Modal>
            </Content>
          </Layout>
        </Layout>
        <Bottom />
      </Layout>,

    ]
    )
  }
}
export default withRouter(Revoke)