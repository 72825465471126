export const host = 'https://www.tracedxal.com'
//export const host = 'http://127.0.0.1:8082'

export const API_USER_LIST = `${host}/api?results=10&page=1&pagination[current]=1&pagination[pageSize]=10"`

export const API_USER_LOGIN = `${host}/api/user/login`

export const API_USER_REGISTER = `${host}/api/user/register`

export const API_USER_CHECKUSER = `${host}/api/user/checkRepeat`

export const API_USER_LIST_BY_PERMITCODE = `${host}/api/user/queryByPermitCode`

export const API_USER_VERIFY_APPLY = `${host}/api/user/passApply`

export const API_USER_DETAIL = `${host}/api/user/detail`

export const API_USER_UPDATE = `${host}/api/user/update`

export const API_STOCK_QUERY_TRADE = `${host}/api/stock/queryTradeOrder`

export const API_STOCK_QUERY_COLLECT = `${host}/api/stock/queryCollectOrder`

export const API_STOCK_QUERY_ORDER = `${host}/api/stock/queryGeneralOrder`

export const API_STOCK_ADD_COLLECT = `${host}/api/stock/initiateCollect`

export const API_STOCK_ADD_CONVERT = `${host}/api/stock/initiateConvert`

export const API_STOCK_ADD_REVOKE = `${host}/api/stock/initiateRevoke`

export const API_STOCK_AUDIT_TRADE = `${host}/api/stock/auditTrade`

export const API_STOCK_DETAIL = `${host}/api/stock/detail`

export const API_STOCK_ADD_TRADE = `${host}/api/stock/initiateTrade`

export const API_STOCK_EXPORT = `${host}/api/stock/export`

export const API_GOODS_MY_LIST = `${host}/api/goods/myAll`

export const API_GOODS_QUERY_ALL = `${host}/api/goods/queryAllWithRaw`

export const API_GOODS_SAVE = `${host}/api/goods/save`

export const API_GOODS_LOCK = `${host}/api/goods/lock`

export const API_GOODS_AUDIT = `${host}/api/goods/audit`
//从批次数据里提取库存量
export const API_GOODS_QUERY_STORE_FROM_BATCH = `${host}/api/goods/queryStoreFromBatch`
//提取简易批次信息，只携带goodsid,可以替换前者
export const API_STOCK_QUERY_SIMPLE_BATCH = `${host}/api/stock/queryBatchFromStock`
//根据批次查库存
export const API_GOODS_QUERY_BATCH_QOH = `${host}/api/stock/queryGoodsQohFromBatch`

export const API_STOCK_BATCH_QRCODE = `${host}/api/stock/qrcode`

export const API_STAT_STOCK_SIMPLE = `${host}/api/stat/stockSimple`