import { Button, Result,Layout } from 'antd';
import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router';

class ErrorPage extends Component{

    handleClick = ()=>{
        this.props.history.push('/login');
    }
    render(){
        return (
            <Layout>
<Result style={{width:'100%',height:document.documentElement.clientHeight,paddingTop:'150px'}}
    status="404"
    title="请重新登录"
    subTitle="抱歉，您的登录凭据已失效，请重新登录"
    extra={<Button type="primary" onClick={this.handleClick.bind(this)}>回到登录</Button>}
  />
          </Layout>
        )
    }
}

export default withRouter(ErrorPage)