import React from 'react';
import { Row, Col } from 'antd';
import '@components/footer.css';

const handleUnknownClick=()=>{
  alert('等待制作中')
}

function Footer() {

  return (
    <footer id="footer" className="dark">
      <div className="footer-wrap">
        <Row>
        <Col lg={4} sm={24} xs={24} className="logo_font">
        <div className="footer-center">
              <h4>追溯系统</h4>
        </div>
              <div>
                <span onClick={handleUnknownClick.bind(this)}>大兴安岭地区绿色发展联盟商会</span>
              </div>
              <div>
                <span>电话：010-51167333</span>
              </div>
          </Col>
          <Col lg={5} sm={24} xs={24}>
            <div className="footer-center">
              <h2>生态产品追溯</h2>
              <div>
                <a onClick={handleUnknownClick.bind(this)}>
                关于我们
                </a>
              </div>
              <div>
                <a onClick={handleUnknownClick.bind(this)}>产品目录</a>
              </div>
              <div>
                <a onClick={handleUnknownClick.bind(this)}>联系我们</a>
              </div>
             </div>
          </Col>
          <Col lg={5} sm={24} xs={24}>
            <div className="footer-center">
              <h2>快速入口</h2>
              <div>
                <a onClick={handleUnknownClick.bind(this)}>合作申请</a>
                </div>
              <div>
                <a onClick={handleUnknownClick.bind(this)}>追溯小程序</a> 
              </div>
              <div>
                <a onClick={handleUnknownClick.bind(this)}>法律法规政策</a>
                              </div>

                          </div>
          </Col>
          <Col lg={5} sm={24} xs={24}>
            <div className="footer-center">
              <h2>常见问题</h2>
              <div>
                <a href="/changelog">
                  更新记录
                </a>
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/ant-design/ant-design/wiki/FAQ">
                  常见问题
                </a>
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="https://gitter.im/ant-design/ant-design">
                  在线讨论 (中文)
                </a>
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="https://gitter.im/ant-design/ant-design-english">
                  在线讨论 (English)
                </a>
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/ant-design/ant-design/issues">
                  讨论列表
                </a>
              </div>
            </div>
          </Col>
          <Col lg={5} sm={24} xs={24}>
            <div className="footer-center">
              <h2>
                <img className="title-icon" src="https://gw.alipayobjects.com/zos/rmsportal/nBVXkrFdWHxbZlmMbsaH.svg" alt="" />
                相关链接
              </h2>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="http://www.dxal.gov.cn/">大兴安岭</a>
                <span> - </span>
               地区行政公署
              </div>
              <div>
                <a target="_blank" rel="noopener noreferrer" href="http://www.ctc.ac.cn/">国检集团</a>
                
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="bottom-bar">
        <Col lg={4} sm={24} />
        <Col lg={20} sm={24}>
          <span
            style={{
              lineHeight: '16px', paddingRight: 12, marginRight: 11, borderRight: '1px solid rgba(255, 255, 255, 0.55)',
            }}
          >
            <a
              href="https://docs.alipay.com/policies/privacy/antfin"
              rel="noopener noreferrer"
              target="_blank"
            >
              隐私权政策
            </a>
          </span>
          <span style={{ marginRight: 24 }}>
            <a
              href="https://render.alipay.com/p/f/fd-izto3cem/index.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              权益保障承诺书
            </a>
          </span>
          <span style={{ marginRight: 12 }}><a href="https://beian.miit.gov.cn/">京ICP备15030672号-18</a></span>
          <span style={{ marginRight: 12 }}>Copyright © 中国国检测试控股集团股份有限公司</span>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;