import { createAction } from '@utils/redux'
import { API_STAT_STOCK_SIMPLE } from '../constants/api'
import { STAT_STOCK_SIMPLE } from '../constants/stat'

export const dispatchStatStockSimple = payload=>createAction({
    url: API_STAT_STOCK_SIMPLE,
    type:STAT_STOCK_SIMPLE,
    method:'GET',
    payload,
    needToken:true
})