import { Breadcrumb, Layout,Divider,Table,Space,Modal,Button} from 'antd';
import { Col, DatePicker, Drawer, Form, Input, Row, Select } from 'antd';
import { connect } from 'react-redux'
import React,{ Component } from 'react'
import { withRouter } from 'react-router';
import * as actions from '@actions/user'
import { USER_TOKEN } from '@constants/user'
import 'antd/dist/antd.css'
import './index.css'
import { Line } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const {  Content} = Layout;


@connect(state=>state.user, {...actions})
class Access extends Component{

    columns = [
    {
      title: '角色id',
      dataIndex: 'roleId',
    },
    {
      title: '角色名称',
      dataIndex: 'roleName',
    },
    {
      title: '权限列表',
      dataIndex: 'permissions',
    },
    {
      title: '操作',
      dataIndex: 'op',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={this.showDrawer.bind(this,true,record.roleId)}>编辑</a>
        </Space>
      ),
    },
  ];

    data = [
      {
        'key':1,
        'roleId':1,
        'roleName':'农户',
        'permissions':'江西省九江市瑞昌市',
      },
      {
        'key':2,
        'roleId':2,
        'roleName':'收集点',
        'permissions':'江西省九江市瑞昌市',
      },
      {
        'key':3,
        'roleId':3,
        'roleName':'加工商',
        'permissions':'江西省九江市瑞昌市',
      },
      {
        'key':4,
        'roleId':4,
        'roleName':'零售商',
        'permissions':'江西省九江市瑞昌市',
      },
    ];

    selectId = 0


    constructor(){
        super(...arguments)
        this.state = {
            drawerOpen:false,
            loading:false
        }
    }

    showDrawer = (isOpen,rid) => {
      this.setState({drawerOpen:isOpen})
      this.selectId = rid
    };

    save(){
      this.setState({drawerOpen:false})
      console.log('selectedId'+this.selectUserId)

    }



    onFinish = (values) => {
      console.log('Success:', values);
    }

    onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }
  

    render(){
        return(
          [
            
            <Layout key="content">
              <Navbar />
            <Layout >
            <Sidebar />
      <Layout
        style={{
          padding: '0 24px 24px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>管理平台</Breadcrumb.Item>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>权限管理</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{margin:'5px 0'}}/>
        
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 480,

          }}
        >
          <Table  columns={this.columns} dataSource={this.data} />
          <Drawer
        title="Create a new account"
        width={400}
        onClose={this.showDrawer.bind(this,false)}
        visible={this.state.drawerOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={this.showDrawer.bind(this,false)}>Cancel</Button>
            <Button type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
           
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: 'Please choose the type',
                  },
                ]}
              >
                <Select placeholder="Please choose the type">
                  <Option value="private">Private</Option>
                  <Option value="public">Public</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="approver"
                label="Approver"
                rules={[
                  {
                    required: true,
                    message: 'Please choose the approver',
                  },
                ]}
              >
                <Select placeholder="Please choose the approver">
                  <Option value="jack">Jack Ma</Option>
                  <Option value="tom">Tom Liu</Option>
                </Select>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'please enter url description',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter url description" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
        </Content>
      </Layout>
    </Layout>
    <Bottom/>
          </Layout>,
 
          ]
          
        )
    }
}

export default withRouter(Access);

