import React,{ Component } from 'react';
import DocumentTitle from 'react-document-title';
import { enquireScreen } from 'enquire-js';
import Header from './components/header';
import Banner from '@components/banner';
import Footer from '@components/footer';
import logo from './logo.svg';
import 'antd/dist/antd.css';
import './App.css';
import { Breadcrumb, Layout, Menu } from 'antd';


function typeFunc(a) {
  if (a.key === 'line') {
    return 'right';
  } else if (a.key === 'button') {
    return 'bottom';
  }
  return 'left';
}

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const { Content } = Layout;

export default class App extends Component {
  constructor(){
    super(...arguments)
    this.state = {
      isFirstScreen: true,
      isMobile,
    };
  }

  componentDidMount(){
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });

  }

  componentDidCatch(){

  }

  onEnterChange = (mode) => {
    this.setState({
      isFirstScreen: mode === 'enter',
    });
  }

  onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  render(){
    return (
      [
       <Header key="header" isFirstScreen={this.state.isFirstScreen} isMobile={this.state.isMobile} />,
      <Banner key="banner" onEnterChange={this.onEnterChange} />,
      <Footer key="footer" />,
      ]
    )
  }
}