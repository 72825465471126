import { API_STOCK_ADD_COLLECT, API_STOCK_ADD_CONVERT, API_STOCK_ADD_REVOKE, API_STOCK_ADD_TRADE, API_STOCK_AUDIT_TRADE, API_STOCK_BATCH_QRCODE, API_STOCK_DETAIL, API_STOCK_EXPORT, API_STOCK_QUERY_COLLECT, API_STOCK_QUERY_ORDER, API_STOCK_QUERY_SIMPLE_BATCH, API_STOCK_QUERY_TRADE } from "../constants/api";
import {STOCK_ADD_COLLECT, STOCK_ADD_CONVERT, STOCK_ADD_REVOKE, STOCK_ADD_TRADE, STOCK_AUDIT_TRADE, STOCK_BATCH_QRCODE, STOCK_DETAIL, STOCK_EXPORT, STOCK_QUERY_COLLECT, STOCK_QUERY_ORDER, STOCK_QUERY_SIMPLE_BATCH, STOCK_QUERY_TRADE} from '../constants/stock';
import { createAction } from '@utils/redux'

export const dispatchStockQueryTrade = payload=>createAction({
    url: API_STOCK_QUERY_TRADE,
    type:STOCK_QUERY_TRADE,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockQueryCollect = payload=>createAction({
    url: API_STOCK_QUERY_COLLECT,
    type:STOCK_QUERY_COLLECT,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockQueryOrder = payload=>createAction({
    url: API_STOCK_QUERY_ORDER,
    type:STOCK_QUERY_ORDER,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockDetail = payload=>createAction({
    url: API_STOCK_DETAIL,
    type: STOCK_DETAIL,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockAddCollect = payload=>createAction({
    url: API_STOCK_ADD_COLLECT,
    type:STOCK_ADD_COLLECT,
    method:'POST',
    payload,
    needToken:true,
    reqType:'requestParam'
})

export const dispatchStockAddConvert = payload=>createAction({
    url: API_STOCK_ADD_CONVERT,
    type:STOCK_ADD_CONVERT,
    method:'POST',
    payload,
    needToken:true,
    reqType:'requestBody'
})

export const dispatchStockAddTrade = payload=>createAction({
    url: API_STOCK_ADD_TRADE,
    type:STOCK_ADD_TRADE,
    method:'POST',
    payload,
    needToken:true
})

export const dispatchStockAddRevoke = payload=>createAction({
    url: API_STOCK_ADD_REVOKE,
    type:STOCK_ADD_REVOKE,
    method:'POST',
    payload,
    needToken:true,
    reqType:'requestBody'
})

export const dispatchStockAuditTrade = payload=>createAction({
    url: API_STOCK_AUDIT_TRADE,
    type:STOCK_AUDIT_TRADE,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockQuerySimpleBatch = payload=>createAction({
    url: API_STOCK_QUERY_SIMPLE_BATCH,
    type:STOCK_QUERY_SIMPLE_BATCH,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockBatchQRCode = payload=>createAction({
    url: API_STOCK_BATCH_QRCODE,
    type:STOCK_BATCH_QRCODE,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchStockExport = payload=>createAction({
    url: API_STOCK_EXPORT,
    type:STOCK_EXPORT,
    method:'GET',
    payload,
    needToken:true,
    reqType:'download'
})


