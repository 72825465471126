import {Drawer, Input,Form,  Tag, Select,Table, Layout, Breadcrumb, Divider, Space, Button, Col, Row, notification, Switch,Modal, InputNumber } from 'antd';
import * as actions from '@actions/user'

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../../utils/common';
import './index.css'




const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;




@connect(state => state, { ...actions })
class User extends Component {
   formRef = React.createRef();

  constructor() {
    super(...arguments)
    
    this.state = {
      userList: [],
      showUserModal: false,
      drawerOpened:false,
      userTotal:0,
      openModal:false,
      auditInfo:{
        userId:0,
        term:1,
        orgName:'',
        message:''
      }
      
    }
    this.selectedBatch = []
    this.columns = [{
      title: '用户id',
      dataIndex: 'userId',
      key: 'user_id',
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
        title: '电话',
        dataIndex: 'mobile',
        key: 'mobile',
      },
    {
      title: '组织机构',
      dataIndex: 'orgName',
      key: 'orgName',
    },
    {
      title: '角色',
      dataIndex: 'permitCode',
      key: 'permitCode',
      render:(permitCode)=>{
        let roles = [{code:65535,name:'管理员',color:''},{code:128,name:'认证机构',color:'red'},{code:64,name:'收集点',color:'volcano'},{code:16,name:'加工厂',color:'orange'},{code:8,name:'零售点',color:'gold'}]
        return(
          permitCode==65535?<Tag color='magenta'>'管理员</Tag>:
          roles.map(item=>{
           return (item.code&permitCode)==item.code?<Tag color={item.color}>{item.name}</Tag>:''
          })
          
        )
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render:(status)=>{
        return(
            status===0?<Tag color="magenta">待审核</Tag>:status===1?<Tag color="green">正常</Tag>:<Tag color="geekblue">已驳回</Tag>
        )
      }
    },
    {
        title: '操作',
        key: 'action',
        render:(row)=>{
          return (
            row.status===0?<Space size="middle"><Button type="link" onClick={this.handleShowModal.bind(this,row)}>审核</Button>
            <Button type="link" onClick={
              this.showDrawer.bind(this,row.userId)
            }>编辑资料</Button></Space>:<Button type="link"  onClick={this.showDrawer.bind(this,row.userId)}>编辑资料</Button>
            

          )}
      }
    ]

    this.editedUser ={
      isValid:false,
      userId:0,username:'',password:'',confirmPassword:'',mobile:'',email:'',
      realName:'',orgName:'',orgCode:'',province:'',city:'',county:'',address:'',postcode:'',cop:''
    }
    this.modelName = '用户管理'
    this.rules = Common.initRules();
  }

  componentDidMount() {
    this.loadData(1)
    console.log('------0--->',this)
    }

  openNotification = (desc) => {
    notification.info({
      message: `提示`,
      description: desc,
      placement: 'top',
    });
  }

  revoke = (batchid,volume)=>{
    this.openNotification("待开发")
  }

  audit = (isPassed)=>{
    let {userId,term} = this.state.auditInfo
    console.log('audit-------->',this.state.auditInfo,isPassed)
   this.props.dispatchUserVerifyApply({userId:userId,isPass:isPassed,term:term}).then(res=>{
    if(res.code===0)
    {
        this.openNotification('审批完成')
        let data = this.state.userList
        let item = data.find(item=>{return item.userId===userId})
        item.status=(isPassed===1?1:2)
        item.key = Math.random()*10
        console.log('USER===>',data)
        this.setState({openModal:false,userList:data,auditInfo:{userId:0,isPassed:0,term:1}})
    }else{
        this.openNotification(res.message)
    }
   }).catch(err=>{
    this.openNotification(err.toString())
   })
  }
  /**
   * 加载用户列表
   * @param {页码} page 
   * @param {类型:all/in/out} type 
   */
  loadData = (page) => {
    this.props.dispatchUserListByPermitCode({ pageIndex: page }).then(
      (res) => {
        if (res.code == 0) {
          console.log('Userlist--->',res)
          let data = Common.addKey(res.result.records);
          this.setState({ userList: data,userTotal:res.result.total })
        } else {
          this.openNotification(res.message)
        }
      }
    ).catch(err => { this.openNotification(err.toString()) })
  }


  /**
   * 单行文本框回调
   * @param {事件载体} e 
   */
  handleInput = (e) => {
    let stock = this.state.stockInfo;
    stock[e.target.name] = e.target.value
    this.setState({ stockInfo: stock })
  }


  handleSelect = (e, item) => {
    let stock = this.state.stockInfo;
    stock.goodsId = parseInt(item.value)
    console.log("GID", item.value, stock.goodsId)
    this.setState({ stockInfo: stock })
  }


  onSearch = () => {
    console.log("search")
    console.log("PARAM:", Common.getQueryString("roleId"))
  }

  addKey = (arr) => {
    for (let i = 0; i < arr.length; i++)
      arr[i]['key'] = i
    return arr
  }

  showDrawer =(userId)=>{
    this.props.dispatchUserDetail({userId:userId}).then(res=>{
      if(res.code!=0){
        this.openNotification(res.message);
        this.closeDrawer();
      }
      else{
        this.editedUser = res.result;
        delete this.editedUser.password
        delete this.editedUser.createTime
        delete this.editedUser.updateTime
        this.editedUser.status = this.editedUser.status===1
        
        setTimeout(()=>{
          this.formRef.current.setFieldsValue(this.editedUser );
        },200)
        this.setState({
          x:1
        },console.log('DRAWER----->',this.state.x))
      }
    }).catch(err=>{
      this.openNotification(err.toString)
    })

    console.log(this.state.drawerOpened)
    this.setState({drawerOpened:true})
  }

  closeDrawer=()=>{
    console.log('SUBMIT======>',this.formRef.current.getFieldsValue(true))
    this.setState({drawerOpened:false})
  }

  onSave=()=>{
    this.formRef.current.validateFields().then((e)=>{
      let user = this.formRef.current.getFieldsValue(true)
      user.status = user.status?1:2
      this.props.dispatchUserUpdate(user).then(res=>{
        if(res.code==0){
          this.openNotification('修改成功')
        } else{
          this.openNotification('修改失败.'+res.message)
        }
        this.closeDrawer()
      }).catch(err=>{
        this.openNotification(err.toString())
      })
    }).catch(err=>{
      this.openNotification('请检查所填内容')
      console.log('Verify---->failed',err)
    })
  }

  onDateChanged=(e)=>{
    console.log(e)
  }

  handleShowModal = (uinfo)=>{
    console.log('Row data--->',uinfo)
    let caExpired = new Date(uinfo.caExpired)
    let term = ((new Date('2010-01-01'))-caExpired>0)?0:1 
    this.setState({openModal:true,auditInfo:{userId:uinfo.userId,term:term,orgName:uinfo.orgName}})
  }

  closeModal=()=>{
    this.setState({openModal:false})
  }

  handleTermChanged = (e)=>{
    let auditInfo = this.state.auditInfo
    auditInfo.term = parseInt(e)
    this.setState({auditInfo:auditInfo},()=>{console.log(this.state.auditInfo)})
  }

  render() {
    return ([

      <Layout key="content">
        <Navbar />
        <Layout >
          <Sidebar />
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>管理平台</Breadcrumb.Item>
              <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
            </Breadcrumb>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={8}>
              <Col span={6} ><Button type="primary" style={{ marginLeft: '8px' }}>导出数据</Button>

              </Col>
              <Col span={2}> </Col>
              <Col span={8}></Col>

              <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/*<Search placeholder="输入用户名" onSearch={this.onSearch.bind(this)} style={{ width: 200, marginRight: '8px' }} />*/}
              </Col>
            </Row>
            <Content
              className="site-layout-background"
              style={{
                padding: '8px',
                margin: 0,
                minHeight: 480,

              }}
            >
              <Table
                columns={this.columns}
                dataSource={this.state.userList}
                pagination={{
                  showQuickJumper:true,
                  pageSize:Common.pageSize,
                  total:this.state.userTotal,
                  onChange:(pageNo, _) => this.loadData(pageNo)
                }}
              />
            </Content>
          </Layout>
        </Layout>
        <Bottom />
      </Layout>,
      <Modal key='modal' title="用户审核" visible={this.state.openModal} onCancel={this.closeModal.bind(this)}
      footer={[
        <Button key='pass' type="primary" onClick={this.audit.bind(this,1)}>同意</Button>,
        <Button key='reject' onClick={this.audit.bind(this,0)}>驳回</Button>,
      ]}
      >
      <Form>
        <Form.Item label="组织机构">
                <Input disabled={true} name='orgName' value={this.state.auditInfo.orgName}/>
        </Form.Item>
        <Form.Item label="认证有效期">
                <InputNumber  disabled={this.state.auditInfo.term===0} name='term' min={1} max={10} value={this.state.auditInfo.term} style={{width:'60px'}}  onChange={this.handleTermChanged.bind(this)}/><Space style={{marginLeft:'5px',marginRight:'5px'}}>年</Space>
                {this.state.auditInfo.term>0?<Tag color='green'>已申请认证</Tag>:<Tag color='red'>未申请认证</Tag>  }
        </Form.Item>
      </Form>
    </Modal>
      ,
      <Drawer
      key='drawer'
      title="编辑用户信息"
      width={720}
      onClose={this.closeDrawer.bind(this)}
      visible={this.state.drawerOpened}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <Button onClick={this.closeDrawer.bind(this)}>取消</Button>
          <Button onClick={this.onSave.bind(this)} type="primary">
            保存
          </Button>
        </Space>
      }
    >
      <Form layout="vertical"  
       ref={this.formRef}
       name="control-ref"
       onFinishFailed={console.log('FAIL SUBMIT----1--->',this.formRef.current)}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="realName"
              label="真实姓名"
              rules={this.rules.realName}
              
            >
              <Input placeholder="真实姓名" value='sda' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label="手机号"
              rules={this.rules.mobile}
            >
              <Input
                style={{
                  width: '100%',
                }}
                placeholder="请输入正确的手机号"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="orgName"
              label="机构名称"
              rules={this.rules.general}
            >
              <Input
                placeholder="请填写组织机构名称"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="orgCode"
              label="统一信用代码"
              rules={this.rules.orgCode}
            >
              <Input
                placeholder="请填写统一信用代码"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              name="province"
              label="省"
              rules={this.rules.general}
            >
              <Input
                placeholder="请填写所在身份"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="city"
              label="市/地区"
              rules={this.rules.general}
            >
              <Input
                placeholder="请填写所在市或地区"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="county"
              label="区/县"
              rules={this.rules.general}
            >
              <Input
                placeholder="请填写所在区或县"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={24}>
            <Form.Item
              name="address"
              label="机构地址"
              rules={this.rules.general}
            >
              <Input
                placeholder="请填写地址"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="邮箱"
              rules={this.rules.email}
            >
              <Input 
                placeholder="请输入邮箱地址"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
           
            <Form.Item
              name="postcode"
              label="邮编"
              rules={this.rules.postcode}
            >
              <Input
                placeholder="请填写地址"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
        <Form.Item name='status' label='状态' valuePropName='checked'>
          <Switch checkedChildren="激活" unCheckedChildren="冻结"/>
        </Form.Item>
        </Col>
        </Row>
      </Form>
    </Drawer>

    ]
    )
  }
}
export default withRouter(User)