import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu, Row, Col, Button, Popover, Badge } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import '@components/header.css';
import { withRouter } from 'react-router';
import logo from '../assets/logo.png'
//import { connect } from 'react-redux'
import * as actions from '@actions/user'


const searchEngine = 'Google';
const menuItems = [
  { label: '首页', key: '/' }, // 菜单项务必填写 key
  { label: '登录', key: '/login' },
  { label: '注册', key: '/register'},
];
//@connect(state=>state.user, {...actions})
class Header extends React.Component {
  static propTypes = {
    isFirstScreen: PropTypes.bool,
    isMoblie: PropTypes.bool,
  }
  state = {
    menuVisible: false,
  };
  onMenuVisibleChange = (visible) => {
    this.setState({
      menuVisible: visible,
    });
  }
  handleShowMenu = () => {
    this.setState({
      menuVisible: true,
    });
  }

  handleHideMenu = () => {
    this.setState({
      menuVisible: false,
    });
  }

  handleSelectFilter = (value, option) => {
    const optionValue = option.props['data-label'];
    return optionValue === searchEngine ||
      optionValue.indexOf(value.toLowerCase()) > -1;
  }

  goTo = (url)=>{
    console.log('goto'+url)
    this.props.history.push(url)
    //this.props.dispatch(push(url))
  }

  menuItemClick = (menuItem)=>{
    this.goTo(menuItem.key)
  }

  render() {
    const { isFirstScreen, isMoblie } = this.props;
    const { menuVisible } = this.state;
    const menuMode = isMoblie ? 'inline' : 'horizontal';
    const headerClassName = classNames({
      clearfix: true,
      'home-nav-white': !isFirstScreen,
    });

    const menu = [

      <Menu
      defaultSelectedKeys={['/']}
      mode="horizontal"
      items={menuItems}
      id="nav" key="nav"
      onClick={this.menuItemClick.bind(this)}

      // 点击子菜单触发
    // inlineCollapsed={false}
    >
    </Menu>,
    ];

    return (
      <header id="header" className={headerClassName}>
        {menuMode === 'inline' ? (
          <Popover
            overlayClassName="popover-menu"
            placement="bottomRight"
            content={menu}
            trigger="click"
            visible={menuVisible}
            arrowPointAtCenter
            onVisibleChange={this.onMenuVisibleChange}
          >
            <MenuOutlined
              className="nav-phone-icon"
              onClick={this.handleShowMenu}
            />
          </Popover>
        ) : null}
        <Row>
          <Col lg={8} md={7} sm={24} xs={24}>
            <a id="logo">
              <img alt="logo" src={logo} />
              <span>大兴安岭生态产品追溯系统</span>
            </a>
          </Col>
          <Col lg={16} md={17} sm={0} xs={0}>
            {menuMode === 'horizontal' ? menu : null}
          </Col>
        </Row>
      </header>
    );
  }
}

export default withRouter(Header)