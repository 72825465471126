export const GOODS_MY_LIST = 'GOODS_MY_LIST'

export const GOODS_QUERY_ALL = `GOODS_QUERY_ALL`

export const GOODS_SAVE = `GOODS_SAVE`

export const GOODS_LOCK = 'GOODS_LOCK'

export const GOODS_AUDIT = 'GOODS_AUDIT'

export const GOODS_QUERY_STORE_FROM_BATCH = `GOODS_QUERY_STORE_FROM_BATCH`

export const GOODS_QUERY_BATCH_QOH = 'GOODS_QUERY_BATCH_QOH'