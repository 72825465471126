import Login from '../views/login'
import Register from '../views/register'
import Dashboard from '../views/dashboard'
import Partner from '../views/partner'
import Access from '../views/system/access'
import ErrorPage from '../views/error'
import Trade from '../views/trade'
import Collect from '../views/trade/collect'
import Revoke from '../views/trade/revoke'
import Convert from '../views/trade/convert'
import Retail from '../views/trade/retail'
import Goods from '../views/goods'
import Error from '../views/system/error'
import User from '../views/system/user'
import Profile from '../views/profile'
import App from '../App.js'

export default [
  { path: '/login', component: Login },
  { path: '/register', component: Register},
  { path: '/dashboard', component: Dashboard},
  { path: '/partner', component: Partner},
  { path: '/system/access', component: Access},
  { path: '/system/error', component: Error},
  { path: '/system/user', component: User},
  { path: '/error', component: ErrorPage},
  { path: '/trade/collect', component: Collect},
  { path: '/trade/convert', component: Convert},
  { path: '/trade/revoke', component: Revoke},
  { path: '/trade/retail', component: Retail},
  { path: '/trade', component: Trade},
  { path: '/goods', component: Goods},  
  { path: '/profile', component: Profile}, 
  { path: '/', component: App }
]