import { USER_TOKEN,USER_INFO,USER_LIST} from '@constants/user'
import { API_USER_LIST } from '@constants/api'
import { createAction } from '@utils/redux'
import { API_USER_CHECKUSER, API_USER_DETAIL, API_USER_LIST_BY_PERMITCODE, API_USER_LOGIN, API_USER_REGISTER, API_USER_UPDATE, API_USER_VERIFY_APPLY } from '../constants/api'
import { USER_CHECKUSER, USER_DETAIL, USER_LIST_BY_PERMITCODE, USER_LOGIN, USER_REGISTER, USER_UPDATE, USER_VERIFY_APPLY } from '../constants/user'

//测试用
export const dispatchUserToken = payload=>createAction({
    url:'http://127.0.0.1',
    type:USER_TOKEN,
    payload
})

//测试用
export const dispatchUserList = payload=>createAction({
    url:API_USER_LIST,
    type:USER_LIST,
    method:'GET',
    payload
})

export const dispatchUserLogin = payload=>createAction({
    url:API_USER_LOGIN,
    type:USER_LOGIN,
    payload,
    reqType:'requestParam'
})

export const dispatchUserRegister = payload=>createAction({
    url:API_USER_REGISTER,
    type:USER_REGISTER,
    payload
})

export const dispatchUserCheck = payload =>createAction({
    url:API_USER_CHECKUSER,
    type:USER_CHECKUSER,
    method:'GET',
    payload
})

export const dispatchUserListByPermitCode = payload => createAction({
    url: API_USER_LIST_BY_PERMITCODE,
    type: USER_LIST_BY_PERMITCODE,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchUserVerifyApply = payload => createAction({
    url: API_USER_VERIFY_APPLY,
    type: USER_VERIFY_APPLY,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchUserDetail = payload => createAction({
    url: API_USER_DETAIL,
    type: USER_DETAIL,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchUserUpdate = payload => createAction({
    url: API_USER_UPDATE,
    type: USER_UPDATE,
    method:'POST',
    payload,
    needToken:true,
    reqType:'requestBody'
})
