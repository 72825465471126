import {  Layout ,Menu} from 'antd';
import { connect } from 'react-redux'
import React,{ Component } from 'react'
import { withRouter } from 'react-router';
import * as actions from '@actions/user'
import { USER_TOKEN } from '@constants/user'
import 'antd/dist/antd.css'
import '@components/sidebar.css'

const {  Sider} = Layout;
class Sidebar extends React.Component{

    constructor(){
        super(...arguments)
        this.test = 100;
        let localData = window.localStorage.getItem("token")
        this.hasToken = false;
    if(localData==null||localData.length==0){
       return
    } else {
      this.hasToken = true;
      localData = JSON.parse(localData)
    }
      this.state = {
        permitCode:localData.user.permitCode
      }
      console.log(localData.user.permitCode)
    }

    menuItems = [
        { label: '首页', key: '/dashboard',code:0}, // 菜单项务必填写 key
        { label: '合作伙伴管理', key: 'partner' ,code:65535,
          children:[
            {label: '收集点', key: '/partner?permitCode=64',code:65535},
            {label: '加工厂', key: '/partner?permitCode=16',code:65535},
            {label: '零售商', key: '/partner?permitCode=8',code:65535},
            {label: '认证机构', key: '/partner?permitCode=128',code:128},
        ]},
        { label: '交易管理', key: 'trade',code:128,
          children:[
            { label: '订单管理', key: '/trade?type=all',code:65535 },
            { label: '收集管理', key: '/trade/collect' ,code:64},
            { label: '我的买入', key: '/trade?type=buy' ,code:0},
            { label: '我的卖出', key: '/trade?type=sold' ,code:0},
            { label: '零售管理', key: '/trade/retail' ,code:8},
            { label: '移除管理', key: '/trade/revoke' ,code:0},
            { label: '加工管理', key: '/trade/convert' ,code:16},
          
          ]},
        { label: '商品配置', key: 'goodsConfig',children:[{ label: '商品管理', key: '/goods', code:0}]},
        { label: '系统管理', key: 'system', code:65535,
          children:[
            { label: '用户管理', key: '/system/user', code:65535 },
            { label: '个人中心', key: '/profile', code:0 },
          ]},

      ];


    handleMenuClick= (e)=>{
      console.log('MENU-------->',e)
      if(e.key==='/system/access')
      {alert('待开放')
      return
    }
      this.props.history.push(e.key);
      //window.location.reload();
      if('callback' in this.props)
        this.props.callback(e.key);
    }

    //权限过滤测试，code为权限测试代码
    filter = (items)=>{
      if(!this.hasToken)
        return [];
      let newItems = new Array();
      for(let i=0;i<items.length;i++){
        if('children' in items[i])
        {
          let newItem = this.filter(items[i].children)
          if(newItem.length>0){
            items[i].children = newItem
            newItems.push(items[i])
          }
        }
        else{
         // console.log('PERMIT====>',this.state.permitCode,items[i]['code'])
          if('code' in items[i]&&(this.state.permitCode&items[i]['code'])==items[i]['code'] ){
            newItems.push(items[i])
          }
        }
      }
      return newItems
    }

    render(){
       let items = this.filter(this.menuItems);
       // console.log(this.filter(this.menuItems))
        return (
            <Sider width={200} className="site-layout-background">
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['partner','trade','goodsConfig','system']}
              style={{
                height: '100%',
                borderRight: 0,
              }}
              items={items}
              onClick={this.handleMenuClick.bind(this)}
            />
          </Sider>
        );
    }
}

export default withRouter(Sidebar)