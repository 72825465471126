import { Layout,Avatar, Button} from 'antd';
import { connect } from 'react-redux'
import React,{ Component } from 'react'
import { withRouter } from 'react-router';
import * as actions from '@actions/user'
import { USER_TOKEN } from '@constants/user'
import 'antd/dist/antd.css'
import logo from '../assets/logo.png'
import '@components/navbar.css'

const {  Header} = Layout;

class Navbar extends React.Component{

  constructor(){
    super(...arguments)
    let localData = window.localStorage.getItem("token")
    this.hasToken = false;
    if(localData==null||localData.length==0){
      this.hasToken = false;
      this.props.history.push('/error')
      return;
    } else {
      this.hasToken = true;
      localData = JSON.parse(localData)
    }
    this.state = {
      username:localData.user.username,
      userId:localData.user.userId,
      permitCode:localData.user.permitCode
    }

  }

  toParent = (code)=>{
    this.props.callback(code);
  }

  logout = ()=>{
    window.localStorage.clear();
    this.props.history.push('/login')
  }

  profile=()=>{
    this.props.history.push('/profile')
  }

    navItems = [
        { label: '个人中心', key: '/profile' },
     
      ]

    render(){
        return (
          this.hasToken==true?
<Header className="header navMenu">
              <a id="logo" style={{position:'fixed',left:'30px'}}>
              <img alt="logo" src={logo}/>
            </a>

            <Avatar
        style={{
          backgroundColor: '#f56a00',
          verticalAlign: 'middle',
        }}
        size="large"
        gap={3}
      >
        {this.state.permitCode==65535? '管理':'会员'}
      </Avatar>
      <Button
        size="small"
        style={{
          margin: '0 16px',
          verticalAlign: 'middle',
        }}
      >
        {this.state.username}
      </Button>
      <Button type="link" onClick={this.profile.bind(this)}>个人中心</Button>
      <Button type="link" onClick={this.logout.bind(this)}>退出登录</Button>
            </Header>:'')
        
    }

}

export default withRouter(Navbar)