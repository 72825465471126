import { Button, Result } from 'antd';
import React,{Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '@actions/user'
import { withRouter } from 'react-router';

@connect(state=>state,{...actions})
class Error extends Component{

    goto = ()=>{
        this.props.history.push('/dashboard')
    }

    render(){
        return (
            <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={this.goto.bind(this)}>Back Home</Button>}
          />
        )
    }
}

export default withRouter(Error)
