import { createAction } from '@utils/redux'
import { API_GOODS_SAVE, API_GOODS_AUDIT, API_GOODS_MY_LIST, API_GOODS_QUERY_ALL, API_GOODS_QUERY_BATCH_QOH, API_GOODS_QUERY_STORE_FROM_BATCH, API_GOODS_LOCK } from '../constants/api'
import { GOODS_SAVE, GOODS_AUDIT, GOODS_MY_LIST, GOODS_QUERY_ALL, GOODS_QUERY_BATCH_QOH, GOODS_QUERY_STORE_FROM_BATCH, GOODS_LOCK } from '../constants/goods'

export const dispatchGoodsMyAll = payload=>createAction({
    url: API_GOODS_MY_LIST,
    type:GOODS_MY_LIST,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchGoodsQueryAll = payload=>createAction({
    url: API_GOODS_QUERY_ALL,
    type:GOODS_QUERY_ALL,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchGoodsSave = payload=>createAction({
    url: API_GOODS_SAVE,
    type:GOODS_SAVE,
    method:'POST',
    payload,
    needToken:true
})

export const dispatchGoodsLock = payload=>createAction({
    url: API_GOODS_LOCK,
    type:GOODS_LOCK,
    method:'GET',
    payload,
    needToken:true
})



export const dispatchGoodsAudit = payload=>createAction({
    url: API_GOODS_AUDIT,
    type:GOODS_AUDIT,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchGoodsQueryStore = payload=>createAction({
    url: API_GOODS_QUERY_STORE_FROM_BATCH,
    type:GOODS_QUERY_STORE_FROM_BATCH,
    method:'GET',
    payload,
    needToken:true
})

export const dispatchGoodsQueryBatchQoh = payload=>createAction({
    url: API_GOODS_QUERY_BATCH_QOH,
    type:GOODS_QUERY_BATCH_QOH,
    method:'GET',
    payload,
    needToken:true
})