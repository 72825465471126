import { Breadcrumb, Tag,Layout,Divider,Table,Space,Modal,Button,Col, Row,Input,notification,Form,InputNumber} from 'antd';
import { connect } from 'react-redux'
import React,{ Component } from 'react'
import { withRouter } from 'react-router';
import * as actions from '@actions/user'
import { USER_TOKEN } from '@constants/user'
import 'antd/dist/antd.css'
import './index.css'
import { Line } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../utils/common';
const {  Content} = Layout;
const { Search } = Input;


@connect(state=>state.user, {...actions})
class Partner extends Component{

    columns = [
    {
      title: '用户ID',
      dataIndex: 'userId',
    },
    {
      title: '组织名称',
      dataIndex: 'orgName',
    },
    {
      title: '所属地区',
      dataIndex: 'address',
    },
    {
      title: '角色',
      dataIndex: 'roles',
    },
    {
      title: '状态',
      dataIndex: 'status',
      render:(status)=>{
        return(
            status==0?<Tag color="magenta">待审核</Tag>:status==1?<Tag color="green">正常</Tag>:<Tag color="geekblue">已驳回</Tag>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      render:(row)=>{
        return (
          row.status===0?<Space size="middle"><Button type="link" onClick={this.handleShowModal.bind(this,row)}>审核</Button></Space>:''
        )
        }
    },
  ];


    selectUserId = 0


    constructor(){
        super(...arguments)
        this.state = {
            test:1,
            open:false,
            loading:false,
            userList:[],
            openModal:false,
            auditInfo:{
              userId:0,
              term:1,
              orgName:'',
              message:''
            }
        }
        console.log('CODE===>',Common.getQueryString('permitCode'))
        this.permitCode = parseInt(Common.getQueryString('permitCode'));
       
        
    }

    

    loadData = (pCode,pageIndex)=>{
      this.props.dispatchUserListByPermitCode({permitCode:pCode,pageIndex:pageIndex})
      .then((res)=>{
        console.log("RES===>",res)
        if(res.code==0){
          console.log("DATA===>",res.result)
          for(let i=0;i<res.result.records.length;i++){
            let roles = Common.permitCodeToName(res.result.records[i].permitCode)
            //console.log("ROLES===>",roles)
            res.result.records[i]['key'] = i
            res.result.records[i]['roles'] = roles.reduce((p,c)=>{if(p.length==0){return c.name}else{return p+','+c.name}},'')
          }
          this.setState({userList:res.result.records})
        }else{
          alert(res.message)
        }
      })
    }

    componentDidMount(){
      this.loadData(this.permitCode,1)
    }

    showModal = (isOpen,uid) => {
      this.setState({open:isOpen})
      this.selectUserId = uid
    };



    audit = (isPassed)=>{
      let {userId,term} = this.state.auditInfo
      console.log('audit-------->',this.state.auditInfo,isPassed)
     this.props.dispatchUserVerifyApply({userId:userId,isPass:isPassed,term:term}).then(res=>{
      if(res.code===0)
      {
          this.openNotification('审批完成')
          let data = this.state.userList
          let item = data.find(item=>{return item.userId===userId})
          item.status=(isPassed===1?1:2)
          item.key = Math.random()*10
          console.log('USER===>',data)
          this.setState({openModal:false,userList:data,auditInfo:{userId:0,isPassed:0,term:1}})
      }else{
          this.openNotification(res.message)
      }
     }).catch(err=>{
      this.openNotification(err.toString())
     })
    }

    onFinish = (values) => {
      console.log('Success:', values);
    }

    onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    }

    onSearch = ()=>{
      console.log("search")
      console.log("PARAM:",Common.getQueryString("roleId"))
    }
  
    onChildMessage(code){
      let reg = new RegExp("(.*?Code=)([0-9]*)")
      let arrMatch = code.match(reg)
      console.log('REG=====>',arrMatch)
      if(arrMatch!=null&&arrMatch.length==3){
      console.log('MSG TRANS===>', code,arrMatch[2],this)
      this.loadData(parseInt(arrMatch[2]),1)
      }
    }
    openNotification = (desc) => {
      notification.info({
        message: `提示`,
        description: desc,
        placement: 'top',
      });
    }

    handleShowModal = (uinfo)=>{
      console.log('Row data--->',uinfo)
      let caExpired = new Date(uinfo.caExpired)
      let term = ((new Date('2010-01-01'))-caExpired>0)?0:1 
      this.setState({openModal:true,auditInfo:{userId:uinfo.userId,term:term,orgName:uinfo.orgName}})
    }
  
    closeModal=()=>{
      this.setState({openModal:false})
    }

    handleTermChanged = (e)=>{
      let auditInfo = this.state.auditInfo
      auditInfo.term = parseInt(e)
      this.setState({auditInfo:auditInfo},()=>{console.log(this.state.auditInfo)})
    }

    render(){
        return(
          [
            
            <Layout key="content">
              <Navbar />
            <Layout >
            <Sidebar callback={this.onChildMessage.bind(this)}/>
      <Layout
        style={{
          padding: '0 24px 24px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>管理平台</Breadcrumb.Item>
          <Breadcrumb.Item>首页</Breadcrumb.Item>
        </Breadcrumb>
        <Divider style={{margin:'5px 0'}}/>
        <Row gutter={8}>
        <Col span={4} ><Button type="primary" style={{marginLeft:'8px'}}>导出数据</Button></Col>
        <Col span={12}></Col>
        
        <Col span={8} style={{display:'flex',justifyContent:'flex-end'}}>
          {/*<Search placeholder="输入组织名称" onSearch={this.onSearch.bind(this)} style={{width: 200,marginRight:'8px'}}    />*/}
    </Col>
        </Row>
        <Modal title="用户审核" visible={this.state.openModal} onCancel={this.closeModal.bind(this)}
      footer={[
        <Button key='pass' type="primary" onClick={this.audit.bind(this,1)}>同意</Button>,
        <Button key='reject' onClick={this.audit.bind(this,0)}>驳回</Button>,
      ]}
      >
      <Form>
        <Form.Item label="组织机构">
                <Input disabled={true} name='orgName' value={this.state.auditInfo.orgName}/>
        </Form.Item>
        <Form.Item label="认证有效期">
                <InputNumber  disabled={this.state.auditInfo.term===0} name='term' min={1} max={10} value={this.state.auditInfo.term} style={{width:'60px'}}  onChange={this.handleTermChanged.bind(this)}/><Space style={{marginLeft:'5px',marginRight:'5px'}}>年</Space>
                {this.state.auditInfo.term>0?<Tag color='green'>已申请认证</Tag>:<Tag color='red'>未申请认证</Tag>  }
        </Form.Item>
      </Form>
    </Modal>
        <Content
          className="site-layout-background"
          style={{
            padding: '8px',
            margin: 0,
            minHeight: 480,

          }}
        >
          <Table className='dataTable'  columns={this.columns} dataSource={this.state.userList} 
           pagination={{
            showQuickJumper:true,
            pageSize:Common.pageSize,
            total:this.state.userTotal,
            onChange:(pageNo, _) => this.loadData(this.permitCode,pageNo)
          }}
          />
          
        </Content>
      </Layout>
    </Layout>
    <Bottom/>
          </Layout>,
 
          ]
          
        )
    }
}

export default withRouter(Partner);

