import { GOODS_SAVE, GOODS_AUDIT, GOODS_MY_LIST, GOODS_QUERY_ALL, GOODS_QUERY_BATCH_QOH, GOODS_QUERY_STORE_FROM_BATCH, GOODS_LOCK } from "../constants/goods"

const initialState = {
    goodsList : []
}

export default function goods(state=initialState, action){
    switch(action.type){
        case GOODS_MY_LIST:
            return{
                ...state,
                ...action.payload
            }
        case GOODS_QUERY_ALL:
            return{
                ...state,
                ...action.payload
            }
        case GOODS_SAVE:
            return{
                ...state,
                ...action.payload
            }
        case GOODS_AUDIT:
            return{
                ...state,
                ...action.payload
            }
        case GOODS_LOCK:
            return{
                ...state,
                ...action.payload
            }
        case GOODS_QUERY_STORE_FROM_BATCH:
            return{
                ...state,
                ...action.payload
            }
        case GOODS_QUERY_BATCH_QOH:
            return{
                ...state,
                ...action.payload
            }
        default:
            return{
                state
            }
    }

}