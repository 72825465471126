import { USER_TOKEN,USER_LIST } from '@constants/user'
import { USER_CHECKUSER, USER_DETAIL, USER_LIST_BY_PERMITCODE, USER_LOGIN, USER_REGISTER, USER_UPDATE, USER_VERIFY_APPLY } from '../constants/user'
const initialState = {
    userInfo:{}
}

export default function user(state = initialState, action){
    switch(action.type){
        case USER_TOKEN:{
            
            return {
                ...state,
                userInfo:{
                    ...action.payload,
                    login:true
                }
            }
        }
        case USER_LIST:{
            console.log('ACT===>',action.payload)
            return {
                ...state,
                data:{...action}
            }
        }
        case USER_LOGIN:{
            let uinfo = {}
            if(action.payload.code==0){
                uinfo = action.payload.result.user
            }
            return {
                ...state,
                userInfo:{
                    ...uinfo,
                    login:true
                }
            }

        }
        case USER_REGISTER:{
            return {
                ...state,
                ...action.payload
            }
        }
        case USER_CHECKUSER:{
            return {
                ...state,
                ...action.payload
            }
        }
        case USER_VERIFY_APPLY:
            return {
                ...state,
                ...action.payload
            }
        case USER_LIST_BY_PERMITCODE:{
            return {
                ...state,
                ...action.payload
            }
        }
        case USER_DETAIL:{
            return {
                ...state,
                ...action.payload
            }
        }
        case USER_UPDATE:{
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}