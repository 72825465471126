import { Form,  Tag, Select, Table, Layout, Breadcrumb, Divider, Space, Modal, Button, Col, Row, Input,notification } from 'antd';
import *  as actions from '@actions/stock'
import * as userAction from '@actions/user'
import * as goodsAction from '@actions/goods'
import React, { Component, useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navbar from '@components/navbar';
import Sidebar from '@components/sidebar'
import Bottom from '@components/bottom';
import Common from '../../utils/common';
import './index.css'



const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const EditableContext = React.createContext(null);

@connect(state => state, { ...actions, ...userAction, ...goodsAction })
class Trade extends Component {


  EditableRow = ({ index, ...props }) => {

    const [form] = Form.useForm();

    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    let [editing, setEditing] = useState(false);
    let inputRef = useRef(null);
    let form = useContext(EditableContext);

    let toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    let save = async () => {
      try {

        let values = await form.validateFields();
        console.log('SAVE===>', record, values)
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  constructor() {
    super(...arguments)
    //this.userinfo = Common.getUserInfo()
    this.queryType = Common.getQueryString('type') == null ? 'all' : Common.getQueryString('type')
     //state.goodsList为每个成员新增了selectedBatches属性，用于保存被复选框选中的批次，修改时一定要跟着改
    this.state = {
      stockList: [],
      queryType: Common.getQueryString('type') === null ? 'all' : Common.getQueryString('type'),
      showBuyModal: false,
      partner: [],
      goodsList: [],
      soldOrderExpanded: false,
      selectedStockIndex: -1,
      stockBatches: [],
      stockVo: {
        opType:2,
        targetUser:{userId:0},
        goods:{goodsId:0},
        batchList:[],
        contractNo:'',
        remark:''
      },
      stockTotal:0
    }
    this.selectedBatch = []
    this.columns = [{
      title: '库存id',
      dataIndex: 'stockId',
      key: 'stock_id',
    },
    {
      title: '卖家',
      dataIndex: 'sourceUsername',
      key: 'source_username',
    },
    {
      title: '买家',
      dataIndex: 'targetUsername',
      key: 'target_username',
    },
    {
      title: '商品',
      dataIndex: 'goodsName',
      key: 'goods_name',
    },
    {
      title: '合同号',
      dataIndex: 'contractNo',
      key: 'contract_no',
    },
    {
      title: '数量',
      dataIndex: 'tradingVolume',
      key: 'trading_volume',
    },
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'create_time',
    },
    {
      title: '状态',
      key: 'is_valid',
      render:(_,record)=>{
        return(
          record.opType==2?<Tag color="magenta">待确认</Tag>: record.opType==5?<Tag color="green">已确认</Tag>:<Tag color="geekblue">已撤销</Tag>
        )
      }
    },
    {
      title:'操作',
      key:'action',
      render:(_,record)=>{
        console.log('AUDIT====》',record)
        return(
          record.opType!=2?'':
          this.queryType==='buy'?<Space  size="middle"> <a onClick={this.auditTrade.bind(this,record.stockId,1)}>同意</a>
          <a onClick={this.auditTrade.bind(this,record.stockId,2)}>撤销</a>
          </Space>:''
        )
      }
    }

    ]
    this.modelName = '交易管理'

    this.goodsColumns = [
      {
        title: '库存编号',
        dataIndex: 'stockId',
        key: 'stockId',
      },
      {
        title: '名称',
        dataIndex: 'cname',
        key: 'cname',
      },
      {
        title: '可用库存',
        dataIndex: 'qoh',
        key: 'qoh',
      },
    ]



    this.subColumes = [
      {
        title: '批次id',
        dataIndex: 'batchId',
        key: 'batchId',
      },
      {
        title: '售出量',
        dataIndex: 'tradingVolume',
        key: 'tradingVolume',
        editable: true,
      },
      {
        title: '买方可用量',
        dataIndex: 'remainingVolume',
        key: 'tradingVolume',
      },
      {
        title:'状态',
        key:'status',
        render:(_,record)=>{
          console.log('Batch--->',record)
          return (
             (new Date(record.caExpired)-new Date(record.createTime))>0?  <Tag color='green'>已认证</Tag>:<Tag color='red'>未认证</Tag>
          )
        }

      }
    ]



    this.editColumns = this.subColumes.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: (row) => {

            if(parseInt(row.tradingVolume)>row.availableVolume){
              this.openNotification("交易量不能超出批次库存")
              return
          }
      
            const newData = [...this.state.stockBatches];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            row.remainingVolume = row.availableVolume-parseInt(row.tradingVolume)
            newData.splice(index, 1, {
              ...item,
              ...row,
            });
            console.log('EDITING====>',this.state.selectedStockIndex,row)
            //goodsList为每个成员新增了selectedBatches属性，用于保存被复选框选中的批次，修改时一定要跟着改
            let stateRowData = this.state.goodsList[this.state.selectedStockIndex]
            let currentOpObj = stateRowData.batchList.find((item)=>{return item.batchId==row.batchId})
            currentOpObj.tradingVolume = parseInt(row.tradingVolume)
            currentOpObj.remainingVolume = currentOpObj.availableVolume - currentOpObj.tradingVolume

            let obj = stateRowData.selectedBatches.find(item=>{return item.batchId==row.batchId})
            if(obj!==undefined){
              obj.tradingVolume = parseInt(row.tradingVolume)
              obj.remainingVolume = obj.availableVolume-  obj.tradingVolume
            }
            let _goods = this.state.goodsList
            _goods[this.state.selectedStockIndex] = stateRowData
            //stateRowData.selectedBatches.find((item)=>{return item.batchId==row.batchId}).tradingVolume = row.tradingVolume
            this.setState({ stockBatches: newData,goodsList:_goods }, () => { console.log('****SAVING========>',stateRowData.selectedBatches.find(item=>{return item.batchId==row.batchId})) })
            //setDataSource(newData);

          },
        }),
      };
    });

    console.log('HOST------>',Common.host)

  }

  // handleSave = (row) => {console.log(row)}

  componentDidMount() {
    this.loadData(1, this.queryType)
    this.loadTradeUserData();
    this.loadMyGoodsList({ qoh: 0 });
  }

  openNotification = (desc) => {
    notification.info({
      message: `提示`,
      description: desc,
      placement:'top',
    });
  }

  /**
   * 加载交易单列表
   * @param {页码} page 
   * @param {类型:all/buy/sold} type 
   */
  loadData = (page, type) => {
    this.props.dispatchStockQueryTrade({ pageIndex: page, type: type }).then(
      (res) => {
        if (res.code == 0) {
          let data = res.result.records;
          for (let i = 0; i < data.length; i++) {
            data[i]['sourceUsername'] = data[i].sourceUser.username
            data[i]['targetUsername'] = data[i].targetUser.username
            data[i]['goodsName'] = data[i].goods.cname
            data[i]['key'] = i
          }
          this.setState({ stockList: data,stockTotal:res.result.total },()=>{console.log('Page---->',this.state)})
        } else {
          alert(res.message)
        }
      }
    ).catch(err => { alert(err.toString()) })
  }

  /**
   * 加载所有支持贸易权限的供应商
   */
  loadTradeUserData = () => {
    this.props.dispatchUserListByPermitCode({ permitCode: 88, pageIndex: 0 }).then((res) => {
     
      if (res.code == 0) {
        let partner = res.result.records;
        let uinfo = Common.getUserInfo();
        partner = partner.filter(o=>{if(o.userId!==uinfo.user.userId){return o;}})
        for (let i = 0; i < partner.length; i++) {
          partner[i]['key'] = i;
        }
        this.setState({ partner: partner })
      }
      else {
        alert('数据加载失败')
      }
    })
  }

  loadMyGoodsList = (query) => {
    this.props.dispatchGoodsMyAll({ pageIndex: 0, ...query }).then(res => {
      if (res.code == 0) {
        let data = []
        for (let i = 0; i < res.result.records.length; i++) {
          res.result.records[i]['key'] = i
          res.result.records[i]['cname'] = res.result.records[i].goods.cname
          res.result.records[i]['selectedBatches'] = []
        }
        this.setState({ goodsList: res.result.records }, () => { console.log('GOD=====>', this.state.goodsList) })
      } else {
        alert(res.message);
      }
    }).catch(err => {
      alert(err.toString());
    })
  }

  /**
   * 表格展开回调
   * @param {展开目标} targer
   * @param {是否展开} expended 
   * @param {行数据} record 
   */
  handleExpand = (target, expended, record) => {

    if (typeof record.batchList == 'undefined' || record.batchList == null) {
      console.log('handleExpand=====>', expended, record, target)
      this.props.dispatchStockDetail({ stockId: record.stockId })
        .then((res) => {
          console.log('handleExpand=====>', expended)
          if (res.code == 0) {
            let stockList = []
            if (target === 'goods')
              stockList = this.state.goodsList
            else
              stockList = this.state.stockList

            if (res.result == null) {
              res.result = []
              res.result['batchList'] = []
            }
            stockList[record.key].batchList = res.result.batchList
            for (let j = 0; j < stockList[record.key].batchList.length; j++) {
              stockList[record.key].batchList[j]['key'] = stockList[record.key].batchList[j]['batchId']
            }
            if (target === 'goods') {
              this.setState({ goodsList: stockList }, console.log(this.state.stockList[record.key]))
            } else {
              this.setState({ stockList: stockList }, console.log(this.state.stockList[record.key]))
            }
          }
        })
      console.log(record)
    }
  }

  handleGoodsSelectorExpand = (expended, record) => {

  }

  handleCellChanged = (e) => {
    console.log('INPUT===>', e)
  }

  /**
   * 显示卖出单填写窗口
   */
  handleShowBuyModal = () => {
    this.setState({ showBuyModal: true })
  }

  /**
   * 单行文本框回调
   * @param {事件载体} e 
   */
  handleInput = (e) => {
    let stockVo = this.state.stockVo;
    stockVo[e.target.name] = e.target.value
    this.setState({stockVo:stockVo})
    console.log('INPUT===>', e)
  }
  
  /**
   * 售卖对象被选择后的回调
   * @param {*} e 
   * @param {*} item 
   */
  handlePartnerSelected = (e,item)=>{
    let index = parseInt(item.key)
    let stockVo = this.state.stockVo;
    stockVo.targetUser = this.state.partner[index]
    this.setState({stockVo:stockVo})
  }



  /**
   * 商品选择器选择后的回调,用于拉取批次信息
   * @param {*} e 
   * @param {*} a 
   */
  handleGoodsSelected = (e, a) => {
    if (e !== 'none' && this.state.goodsList[e].batchList === null) {
      let index = parseInt(e)
      this.props.dispatchStockQuerySimpleBatch({ goodsId: this.state.goodsList[index].goods.goodsId,
        minRemainingVolume:0,
        isValid:1,pageIndex:0 }).then(res => {
        if (res.code == 0) {
          let data = this.state.goodsList
          data[index].batchList = Common.addKey(res.result.records)
          for(let i=0;i<data[index].batchList.length;i++){
            data[index].batchList[i].tradingVolume = data[index].batchList[i].remainingVolume
            data[index].batchList[i]['availableVolume'] = data[index].batchList[i].remainingVolume
          }
          this.setState({
            goodsList: data,
            selectedStockIndex: index,
            stockBatches: data[index].batchList
          })
        }
        else {
          alert('数据错误')
        }
      }).catch(err => { console.log(err) })
    } else if (e !== 'none') {
      this.setState({
        selectedStockIndex: parseInt(e),
        stockBatches: this.state.goodsList[parseInt(e)].batchList
      })
    }
  }

  /**
   * 批次表格内的复选框选中回调
   * @param {行的key值} selectedRowKeys 
   * @param {行数据} selectedRows 
   */
  handleBatchCheckboxSelected = (selectedRowKeys, selectedRows) => {
    let selectedBatches = []
    for(let i=0;i<selectedRows.length;i++){
      selectedBatches.push({batchId:selectedRows[i].batchId,
        tradingVolume:parseInt(selectedRows[i].tradingVolume),
        caExpired:selectedRows[i].caExpired,
        stockId:selectedRows[i].stockId
      })
    }
    let data = this.state.goodsList;
    let index = this.state.selectedStockIndex;
    data[index]['selectedBatches'] = selectedBatches
    //let stockVo = this.state.stockVo;
    //stockVo.batchList = selectedBatches
    this.setState({goodsList:data})
    //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  }

  closeBuyModal = () => {
    this.setState({ showBuyModal: false })
  }

  /**
   * 子组件回传数据
   * @param {url} type 
   */
  onChildMessage(type) {
    let reg = new RegExp("(.*?type=)([a-zA-Z]*)")
    let arrMatch = type.match(reg)
    if (arrMatch != null && arrMatch.length == 3) {
      this.setState({ queryType: arrMatch[2] })
      this.loadData(1, arrMatch[2])
    }
  }

  onSearch = () => {
    console.log("search")
    console.log("PARAM:", Common.getQueryString("roleId"))
  }

  onBatchChanged = (a, b, c) => {
    console.log('BAT===>', a, b, c)
  }

  auditTrade = (stockId,isPassed)=>{
    this.props.dispatchStockAuditTrade({stockId:stockId,isPassed:isPassed}).then(res=>{
      if(res.code==0){
        this.openNotification(isPassed===1?"操作成功，交易已完成":"订单已成功撤销，库存解锁。")
        this.loadData(1,this.state.queryType)
      }
      else
        this.openNotification(res.message)
    }).catch(err=>{
      this.openNotification(err.toString())
    })
  }


  /**
   * 提交交易单
   * @returns 
   */
  addTrade = () => {
    //dispatchStockAddTrade
    if (this.state.selectedStockIndex <0 || this.state.stockVo.contractNo.length == 0) {
      this.openNotification('请检查填写信息')
      return
    }
    let data = this.state.goodsList[this.state.selectedStockIndex]
    let stockVo = this.state.stockVo
    stockVo.goods.goodsId = data.goods.goodsId;
    stockVo.batchList = data.selectedBatches;
    let sumVolume = 0;
    for(let i = 0;i<stockVo.batchList.length;i++){
      sumVolume+=stockVo.batchList[i].tradingVolume;
    }
    stockVo.tradingVolume = sumVolume;
    console.log('STOCK-VP========>',stockVo)
    
    this.props.dispatchStockAddTrade(stockVo).then(res => {
      if (res.code == 0) {
        this.openNotification('添加成功')
        this.loadData(1,this.queryType)
        this.loadMyGoodsList({ qoh: 0 });
        this.setState({
        selectedStockIndex: -1,
      stockBatches: [],
      stockVo: {
        opType:2,
        targetUser:{userId:0},
        goods:{goodsId:0},
        batchList:[],
        contractNo:'',
        remark:''
      }})
        this.closeBuyModal()
      } else {
        this.openNotification('添加失败，' + res.message)
        return
      }
    }).catch(err => {
      this.openNotification('添加失败，' + err.toString())
    })
    
  }

  handleDownload = ()=>{
    console.log('mode--->',this.queryType)
    this.props.dispatchStockExport({filename:'export.xlsx',  type:this.queryType}).then(res=>{
        if(res.code===0){
          this.openNotification('下载成功')
        } else{
          this.openNotification(res.message)
        }
    }).catch(err=>{
    this.openNotification(err.toString())  
    })
}


  render() {
    return ([

      <Layout key="content">
        <Navbar />
        <Layout >
          <Sidebar callback={this.onChildMessage.bind(this)} />
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>管理平台</Breadcrumb.Item>
              <Breadcrumb.Item>{this.modelName}</Breadcrumb.Item>
            </Breadcrumb>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={8}>
              <Col span={6} ><Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleDownload.bind(this)}>导出数据</Button>
                {this.state.queryType == 'sold' ? <Button type="primary" style={{ marginLeft: '8px' }} onClick={this.handleShowBuyModal.bind(this)}>我要卖出</Button> : ''}
              </Col>
              <Col span={2}> </Col>
              <Col span={8}></Col>

              <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/*<Search placeholder="输入组织名称" onSearch={this.onSearch.bind(this)} style={{ width: 200, marginRight: '8px' }} />*/}
              </Col>
            </Row>
            <Content
              className="site-layout-background"
              style={{
                padding: '8px',
                margin: 0,
                minHeight: 480,

              }}
            >
              <Table
                columns={this.columns}
                dataSource={this.state.stockList}
                expandedRowRender={(record, index) => {
                  return (<Table columns={this.subColumes} dataSource={this.state.stockList[index].batchList} pagination={{ hideOnSinglePage: true }} />)
                }}
                onExpand={this.handleExpand.bind(this, 'stock')}
                pagination={{
                  showQuickJumper:true,
                  pageSize:Common.pageSize,
                  total:this.state.stockTotal,
                  onChange:(pageNo, _) => this.loadData(pageNo,this.queryType)
                }}
              />
              <Modal
                title="交易单录入"
                style={{
                  top: 20,
                }}
                centered
                visible={this.state.showBuyModal}
                okText='提交'
                cancelText='取消'
                onOk={this.addTrade.bind(this)}
                onCancel={this.closeBuyModal}
                width={600}
                destroyOnClose={true}
              >
                <Form
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 18,
                  }}
                  layout="horizontal"

                >
                  <Form.Item label="买方" name="taget">
                    <Select
                      showSearch
                      placeholder="选择售卖对象"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      onChange={this.handlePartnerSelected.bind(this)}
                    >
                      {this.state.partner.map((item) => { return <Option key={item.key} value={item.userId}>{item.orgName}</Option> })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="合同编号">
                    <Input placeholder='填写合同编号' value={this.state.stockVo.contractNo} name='contractNo' onChange={this.handleInput.bind(this)} />
                  </Form.Item>
                  <Form.Item label="选择商品">
                    <Select defaultValue="未选择" style={{ width: 120 }} onChange={this.handleGoodsSelected.bind(this)} >
                      <Option value="none" key='-1'>未选择</Option>
                      {this.state.goodsList.map((item) => {
                        return (<Option key={item.key} value={item.goodsId}>{item.cname}</Option>)
                      })}
                    </Select>
                  </Form.Item>
                    <Form.Item label="批次详情">
                      <Table rowSelection={{
                        type: 'checkbox',
                        ...{
                          onChange:this.handleBatchCheckboxSelected.bind(this),
                          getCheckboxProps: (record) => ({
                           
                            name: record.name,
                          })
                        }
                      }}
                        components={{
                          body: {
                            row: this.EditableRow,
                            cell: this.EditableCell,
                          },
                        }}
                        rowClassName={() => 'editable-row'}
                        scroll={{ x: '100%' }}
                        columns={this.editColumns}
                        dataSource={this.state.stockBatches}
                        pagination={{ hideOnSinglePage: true }} />
                    </Form.Item> 
                </Form>
              </Modal>
            </Content>
          </Layout>
        </Layout>
        <Bottom />
      </Layout>,

    ]
    )
  }
}
export default withRouter(Trade)