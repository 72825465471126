import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import Login from '@views/login'
//import Role from './views/role';
import { Provider } from 'react-redux';
import configStore from './store';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';

const store = configStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <Switch>
        {routes.map(d => <Route key={d.path} path={d.path} component={d.component} />)}
      </Switch>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
