import { USER_LOGIN } from "../constants/user";

export function createAction(opts) {
  const { url, payload, method, fetchOpt, callback, type, needToken, reqType } = opts
  return (dispatch) => {
    console.log('PARAM===>', reqType, method, type);
    let header = {}
    if (needToken) {
      let token = window.localStorage.getItem("token");
      if (token != null) {
        let localKey = JSON.parse(token)
        header['token'] = localKey.token;
      }
    }
    let body = '';
    if (payload.constructor.name === 'FormData' || reqType === 'requestParam') {
      if (payload.constructor.name === 'Object') {
        body = new FormData();
        for (let k in payload) {
          body.append(k, payload[k]);
        }
      }
      else {
        body = payload;
      }
    }
    else if (reqType === 'download') {

    }
    else {
      body = JSON.stringify(payload);
      header['Content-Type'] = 'application/json';
    }

    //console.log('FORM====>',body,'METHOD==>',method)
    let data = {
      cache: 'no-cache', // 可选的值有*default, no-cache, reload, force-cache, only-if-cached。
      credentials: 'same-origin', // 可选的值有include, same-origin, *omit
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        ...header
      },
      method: method === undefined ? 'POST' : method, // 可选的值有*GET, POST, PUT, DELETE等。
      mode: 'cors', // 可选的值有no-cors, cors, *same-origin
      redirect: 'follow', // 可选的值有manual, *follow, error
      referrer: 'no-referrer', // 可选的值有*client, no-referrer
    };
    let queryString = '?';
    let filename = '';
    if (method === 'GET') {
      let params = []
      for (let k in payload) {
        if (reqType === 'download' && 'filename' === k) {
          filename = payload[k]
        } else
        console.log('normal req---->',payload)
        if(payload[k]!==undefined)
          params.push(k + '=' + payload[k].toString())
        else
         console.log('[WARNING]----->null found:',payload[k])
      }
      queryString += params.join('&')
    }
    else {
      data['body'] = body;
    }
    if (reqType === 'download') {
       return fetch(url + queryString, data).then(
       async (res) => { 
       return await   res.blob().then(async (blob) => {
          if (blob.type === 'application/json') {
            console.log('then---.')
           return await blob.text().then(async (txt) => {
              txt =await JSON.parse(txt);
              console.log('txt--->',txt)
              return Promise.resolve(txt)
            }).catch(err=>{
              return Promise.reject(err)
            })
          } else {
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            filename = filename.length == 0 ? 'data' : filename;
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            //dispatch({type,payload}).then(res=>{console.log('finnal',res)})
            return Promise.resolve({ code: 0, message: 'ok' })
            /*
            第一步：fetch接口获取其内容转化为blob对象；
            第二部：将blob对象使用reateObjectURL转化为ObjectURL，这个相等于下载地址的链接；
            第三步：创建一个a标签，并赋予ObjectURL，且执行一次click；
            第四步：通过revokeObjectURL回收ObjectURL；
            */
          }
        }).catch(err => {
          return Promise.reject(err)
        })
      }
      )

    } else {

      return fetch(url + queryString,
        data)
        .then(async res => {

          res = await res.json();
          //Token拦截
          if (type === USER_LOGIN) {
            if (res.code == 0) {
              let localKey = { token: res.result.token, user: res.result.user }
              //console.log(JSON.stringify(localKey))
              window.localStorage.setItem('token', JSON.stringify(localKey));
              //console.log('SET TOKEN OK!')
            }
          }
          //res=JSON.parse(res)
          dispatch({ type, payload: callback !== undefined ? callback(res) : res })
          return res
        }, err => {
          console.log('!!!!!!' + err)
          dispatch({ type, payload: { code: 501, message: JSON.stringify(err) } })
          return Promise.reject(err)
        }) // 解析JSON响应
        .catch(err => { console.log('!!!!!!' + err); return Promise.reject(err) })
    }
  }
}